import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationSource = new BehaviorSubject([]);
  currentNotification = this.notificationSource.asObservable();

  constructor() { }

  changeNotification(notification: any) {
      this.notificationSource.next(notification);
  }
}
