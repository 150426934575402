import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {AuthenticationService} from "../config/authentication.service";
import {first} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {CryptService} from "../config/crypt.service";
import {LocalStorage} from "@ngx-pwa/local-storage";
import {CartService} from "../config/cart.service";
import {ConfigSettings} from '../config/config.settings';
import { AuthService, SocialUser } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";
import { User } from '../models/user';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import {ConfigService} from "../config/config.service";

declare var ackoo;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    animations: [ listStagger ]
})
export class LoginComponent implements OnInit {
    email: string;
    password: string;
    rememberMe: boolean = false;
    submitted: boolean = false;
    hasError: boolean = false;
    message: string;
    returnUrl: string;

    /*Fields for Register form*/
    registerName: string;
    registerEmail: string;
    registerPassword: string;
    registerConfirmPassword: string;
    registerSubmitted: boolean = false;
    registerHasError: boolean = false;
    registerMessage: string;
    newsletterSubscribed = 0;
    device: string;
    user: User;

    /* Fields for Facebook login*/
    fbHasError: boolean = false;
    guest_register: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private cryptService: CryptService,
        protected localStorage: LocalStorage,
        private cartService: CartService,
        private configSettings: ConfigSettings,
        private configService: ConfigService,
        private authService: AuthService,
        protected footerService: FooterService
    ) {}

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        if (this.returnUrl !== '/') {
            this.guest_register = true;
        }
        this.device = this.configSettings.getBrowserVersion();
        setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
    }

    /**
     * After user submit login form
     * @param loginForm
     */
    onSubmit(loginForm: NgForm) {
        this.configSettings.toggleLoading(true);
        this.doLogin();
        loginForm.reset();
    }

    /**
     * Login user
     */
    doLogin() {
        this.authenticationService.login(this.email, this.password)
            .pipe(first())
            .subscribe(
                response => {
                    if (response.status === 200) {
                        this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                            this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                                this.configService.sendGTagEvent('login', {
                                    user_id: response.data.id,
                                    user_firstname: response.data.first_name,
                                    user_lastname: response.data.last_name,
                                    email: response.data.email
                                });

                                // ackoo integreation
                                this.ackooIdentify({
                                    name: response.data.first_name + response.data.last_name,
                                    email: response.data.email,
                                    createdAt: new Date()
                                });
                                
                                this.cartService.uploadOfflineCart(response.data.id).then(() => {
                                    this.cartService.clearStorage('cart');
                                    this.redirectTo();
                                });
                            });
                        });
                    } else {
                        this.submitted = true;
                        this.hasError = true;
                        this.message = response.message;
                        this.configSettings.toggleLoading(false);
                    }
                }, error => { this.configSettings.toggleLoading(false); }
            );
    }

    /**
     * After user submit register form
     * @param registerForm
     */
    onRegisterSubmit(registerForm: NgForm) {
        this.configSettings.toggleLoading(true);
        this.doRegister();
        registerForm.reset();
    }

    /**
     * Register user
     */
    doRegister() {
        if (!this.newsletterSubscribed) {
            this.newsletterSubscribed = 0;
        }
        try {
            const param = {
                first_name: this.registerName,
                email: this.registerEmail,
                password: this.registerPassword,
                newsletter_subscribed: this.newsletterSubscribed ? 1 : 0,
                device_type: 'W',
                device_model: this.device,
                app_version: this.device,
                os_version: window.navigator.platform
            };
            this.authenticationService.register(param)
                .pipe(first())
                .subscribe(
                    response => {
                        if (response.status === 200) {
                            this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                                this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                                    this.configService.sendGTagEvent('register', {
                                        user_id: response.data.id,
                                        user_firstname: response.data.first_name,
                                        user_lastname: response.data.last_name,
                                        email: response.data.email
                                    });

                                    // ackoo integreation
                                    this.ackooIdentify({
                                        name: response.data.first_name + response.data.last_name,
                                        email: response.data.email,
                                        createdAt: new Date()
                                    }); 

                                    this.cartService.uploadOfflineCart(response.data.id).then(() => {
                                        this.cartService.clearStorage('cart');
                                        this.redirectTo();
                                    });
                                });
                            });
                        } else {
                            this.registerSubmitted = true;
                            this.registerHasError = true;
                            this.registerMessage = response.message;
                            this.configSettings.toggleLoading(false);
                        }
                    }, error => { this.configSettings.toggleLoading(false); }
                );
        } catch (e) {
            console.log(e);
            this.configSettings.toggleLoading(false);
        }
    }

    /**
     * After guest user submit register form
     * @param guestRegisterForm
     */
    onGuestRegisterSubmit(guestRegisterForm: NgForm) {
        this.configSettings.toggleLoading(true);
        this.doGuestRegister();
        guestRegisterForm.reset();
    }

    /**
     * Register guest user
     */
    doGuestRegister() {
        try {
            const param = {
                email: this.registerEmail,
                device_type: 'W',
                device_model: this.device,
                app_version: this.device,
                os_version: window.navigator.platform
            };
            this.authenticationService.guestRegister(param)
                .pipe(first())
                .subscribe(
                    response => {
                        if (response.status === 200) {
                            this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                                this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                                    this.cartService.uploadOfflineCart(response.data.id).then(() => {
                                        this.cartService.clearStorage('cart');
                                        this.redirectTo();
                                    });
                                });
                            });

                            // ackoo integreation
                            this.ackooIdentify({
                                name: param.email.substring(0, param.email.indexOf('@')),
                                email: param.email,
                                createdAt: new Date()
                            }); 

                        } else {
                            this.registerSubmitted = true;
                            this.registerHasError = true;
                            this.registerMessage = response.message;
                            this.configSettings.toggleLoading(false);
                        }
                    }, error => { this.configSettings.toggleLoading(false); }
                );
        } catch (e) {
            console.log(e);
            this.configSettings.toggleLoading(false);
        }
    }

    /**
     * Sign in using facebook
     */
    signInWithFB(): void {
        this.configSettings.toggleLoading(true);
        try {
            this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
                (userData) => {
                    if (!userData.email) {
                        this.message = 'You have problem in your facebook account. Please try again later.';
                        this.fbHasError = true;
                        this.configSettings.toggleLoading(false);
                        return false;
                    }

                    const param = {
                        facebook_id: userData.id,
                        first_name: userData.firstName,
                        last_name: userData.lastName,
                        email: userData.email,
                        social_register_type: 'F',
                        device_type: 'W',
                        device_model: this.device,
                        app_version: this.device,
                        os_version: window.navigator.platform
                    };

                    this.authenticationService.socialRegister(param)
                    .pipe(first())
                    .subscribe(
                        response => {
                            if (response.status === 200) {
                                this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                                    this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                                        this.cartService.uploadOfflineCart(response.data.id).then(() => {
                                            this.cartService.clearStorage('cart');
                                            this.redirectTo();
                                        });
                                    });
                                });

                                this.ackooIdentify(userData.email); // ackoo integreation

                            } else {
                                this.fbHasError = true;
                                this.message = response.message;
                                this.configSettings.toggleLoading(false);
                            }
                        }, error => { this.configSettings.toggleLoading(false); }
                    );
                }, error => { this.configSettings.toggleLoading(false); }
            );
        } catch(e) {
            console.log(e);
            this.configSettings.toggleLoading(false);
        }
    }

    redirectTo() {
        //this.router.navigate([this.returnUrl]);
        window.location.href = this.returnUrl; 
    }

    // ackoo integreation
    ackooIdentify(userInfo) {
        ackoo.identify(userInfo);
    }

}
