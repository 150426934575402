import {Component, Input, OnInit} from '@angular/core';
import {Product} from "../models/product";
import {ConfigSettings} from "../config/config.settings";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.css']
})
export class CartSummaryComponent implements OnInit {
  @Input() currency: string;
  @Input() cartValue: number;
  @Input() shippingCost: number;
  @Input() vatCharges: number = 0;
  @Input() deliveryText: string = '';
  @Input() is_free_delivery = false;
  @Input() codCost: number = 0;
  @Input() discount: number = 0;

  constructor(
      private configSettings: ConfigSettings,
      private translateService: TranslateService
  ) { }

  ngOnInit() {
    if(this.deliveryText == ''){
      this.translateService.get('Delivery').subscribe((res: string) => {
        this.deliveryText = res;
      });
    }

    if(!this.discount){ this.discount = 0; }
  }

}
