import {ConfigurableOptions} from "./configurable-options";
import {Reviews} from "./reviews";

export class Product {
    id: string;
    parent_id: string = "";
    name: string;
    short_description: string;
    description: string;
    specification: string = "";
    shipping_free_returns: string = "";
    SKU: string;
    regular_price: string;
    final_price: string;
    final_price_kwd?: number;
    currency_code: string;
    remaining_quantity: number;
    quantity: number = 0;
    is_featured: number;
    new_from_date: string;
    new_to_date: string;
    brand_name: string;
    boutique_name: string;
    image: string;
    images: string[] = [];
    video: string = "";
    configurable_option: ConfigurableOptions[] = [];
    related_products: Product[] = [];
    reviews: Reviews[] = [];
    is_saleable: number = 0;
    product_type: string = "";
    associated_products: Product[] = [];
    item_in_cart: number = 0;
    item_in_wishlist: number = 0;
    average_rating: string = "";
    size_guide: string = "";
}
