import {EventEmitter, Injectable, Output} from '@angular/core';
import {CryptService} from "./crypt.service";
import {LocalStorage} from "@ngx-pwa/local-storage";
import {ConfigSettings} from "./config.settings";
import {ConfigService} from "./config.service";
import {Address} from "../models/address";
import {Order} from "../models/order";
import {Product} from "../models/product";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    order: Order;
    userId: string;
    items: Product[];
    @Output() orderOutput: EventEmitter<Order> = new EventEmitter<Order>();
    @Output() userIdOutput: EventEmitter<string> = new EventEmitter<string>();
    @Output() wishlistOutput: EventEmitter<Product[]> = new EventEmitter<Product[]>();
    constructor(
        private cryptService: CryptService,
        protected localStorage: LocalStorage,
        private configSettings: ConfigSettings,
        private configService: ConfigService
    ) { }

    getUserSession(): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            this.localStorage.getItem<string>('userInfo').subscribe((userInfo) => {
                if(userInfo) {
                    this.cryptService.doDecrypt(userInfo).then(decryptedString => {
                        const user = JSON.parse(decryptedString);
                        resolve(user);
                    });
                } else {
                    resolve(false);
                }
            }, error => { reject(error); });
        });

        return promise;
    }

    isLoggedIn(): Promise<boolean> {
        let promise = new Promise<boolean>((resolve, reject) => {
            this.localStorage.getItem<string>('userInfo').subscribe((userInfo) => {
                if (userInfo) {
                    resolve(true);
                } else {
                    reject(false);
                }
            });
        });

        return promise;
    }

    getUserAddress(user, lang, store): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            let addresses: Address[] = [];
            const orderId = this.configSettings.getCartId();
            const params = {
                user_id: user,
                lang: lang,
                store: store,
                order_id: (orderId && orderId !== null) ?  orderId : ''
            };
            this.configService.readRequest('user-address', params)
                .subscribe(response => {
                    if(response.body.status === 200) {
                        response.body.data.map((address, index) => {
                            addresses.push(address);
                        });
                    }

                    resolve(addresses);
                }, error => { reject(error); });
        });

        return promise;
    }

    getUserAddressDetails(address, user, store): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            const params = {
                id: address,
                user_id: user,
                lang: this.configSettings.getLang(),
                store: store,
                order_id: this.configSettings.getCartId()
            };
            this.configService.readRequest('address-details', params)
                .subscribe(response => {
                    resolve(response);
                }, error => { reject(error); });
        });

        return promise;
    }

    setOrder(order: Order) {
        this.order = order;
        this.orderOutput.emit(this.order)
    }

    setUserId(id: string) {
        this.userId = id;
        this.userIdOutput.emit(this.userId)
    }

    setWishlist(items: Product[]) {
        this.items = items;
        this.wishlistOutput.emit(this.items)
    }
}
