import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ConfigService} from '../config/config.service';
import {Product} from '../models/product';
import {ActivatedRoute} from '@angular/router';
import {Filters} from "../models/filters";
import {FilterValues} from "../models/filter-values";
import {ConfigSettings} from "../config/config.settings";
import {NgSelectComponent} from '@ng-select/ng-select';
import {Cart} from "../models/cart";
import {Category} from "../models/category";
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Store} from '../models/store';
import {FooterService} from "../config/footer.service";
import { listStagger } from '../animations';
import { User } from '../models/user';
import { UserService } from '../config/user.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css'],
  animations: [ listStagger ]
})
export class ProductListComponent implements OnInit, AfterViewInit {
  //@ViewChild('paramSortSelect') paramSortSelect: NgSelectComponent;
  products: Product[] = [];
  page: number;
  pageSize: number = 20;
  totalPages: number;
  isFeatured: number;
  latest: number;
  bestSelling: number;
  sortBy: number = 1;
  store: Store;
  lang: string;
  title: string;
  minPrice: number = 0;
  maxPrice: number;
  brands: number[] = [];
  attributes: number[] = [];
  filters: Filters[] = [];
  filterValues: FilterValues[];
  params: Object;
  sortOptions: any[] = [];
  categories: Category[] = [];
  category: number;
  selectedCategory: number;
  id: number;
  q: string = '';
  currency: string;
  dataAvailable = false;
  user: User = new User();
  fixedFilter = false;
  currentRoute = '';

  constructor(
      private configService: ConfigService,
      private route: ActivatedRoute,
      private configSettings: ConfigSettings,
      protected localStorage: LocalStorage,
      protected footerService: FooterService,
      private userService: UserService
  ) {
      this.route.queryParams.subscribe(params => {
          this.q = (params['q']) ? params['q'] : '';
      });
  }

  ngOnInit() {
    this.currentRoute = this.route.snapshot.routeConfig.path;
    this.page = 1;
    this.isFeatured = 0;
    this.latest = 0;
    this.bestSelling = 0;
    this.sortBy = 1;
    this.id = this.route.snapshot.params.id;
    this.lang = this.configSettings.getLang();
    this.configSettings.getStoreObject().then((result) => {
        this.store = <Store> result;
        this.currency = result['currency_' + this.lang];
         /**
         * Getting user session data
         */
        this.userService.getUserSession().then(response => {
            if (response !== false) {
                this.user = response;
            }

            this.route.params.subscribe((params) => {
                if (this.id && this.id.toString() !== params['id']) {
                    this.id = +params['id'];
                    this.getProductList();
                }
            });
            this.getProductList();
            this.getSortOptions();
        });
    });
  }

  ngAfterViewInit() {

  }

  getSortOptions() {
        if(this.lang == 'en'){
            this.sortOptions = [
                { value: 1, label: 'Our Picks' },
                { value: 2, label: 'New Items' },
                { value: 3, label: 'Price (high first)' },
                { value: 4, label: 'Price (low first)' },
                { value: 5, label: 'Sale Products' }
            ];
        } else {
            this.sortOptions = [
                { value: 1, label: 'اختياراتنا' },
                { value: 2, label: 'منتجات جديدة' },
                { value: 3, label: 'السعر (الأعلى أولاً)' },
                { value: 4, label: 'السعر (الأقل أولاً)' },
                { value: 5, label: 'تخفيضات فقط' }
            ];
        }
  }

  getProductList() {
      this.configSettings.toggleLoading(true);
      const path = this.route.snapshot.url;
      if (path.some(e => e.path === 'featured')) {
          this.isFeatured = 1;
          this.title = 'Featured';
          this.doSearch();
      } else if (path.some(e => e.path === 'best-sellers')) {
          this.bestSelling = 1;
          this.title = 'Best Sellers';
          this.doSearch();
      } else if (path.some(e => e.path === 'new-arrivals')) {
          this.latest = 1;
          this.title = 'New Arrivals';
          this.doSearch();
      } else if (path.some(e => e.path === 'category')) {
          this.getAllCategories().then(
              response => {
              const categoryId = +this.route.snapshot.paramMap.get('id');
              /*const categoryName = this.route.snapshot.paramMap.get('name');
              const subCategoryName = this.route.snapshot.paramMap.get('name_2');
              const subCategoryName2 = this.route.snapshot.paramMap.get('name_3');
              const subCategoryName3 = this.route.snapshot.paramMap.get('name_4');

              let index = response.findIndex(category => this.configSettings.cleanUrl(category.name) === categoryName);
              if(subCategoryName && response[index].has_subcategory === 'Yes') {
                  let subLevelIndex1 = response[index].subcategories.findIndex(category => this.configSettings.cleanUrl(category.name) === subCategoryName);

                  if(subCategoryName2 && response[index].subcategories[subLevelIndex1].has_subcategory === 'Yes') {
                      let subLevelIndex2 = response[index].subcategories[subLevelIndex1].subcategories.findIndex(category => this.configSettings.cleanUrl(category.name) === subCategoryName2);
                      this.categories = (response[index].subcategories[subLevelIndex1].subcategories[subLevelIndex2].has_subcategory === 'Yes') ? response[index].subcategories[subLevelIndex1].subcategories[subLevelIndex2].subcategories : null;
                      this.title = response[index].subcategories[subLevelIndex1].subcategories[subLevelIndex2].name;





                      let subLevelIndex3 = response[index].subcategories[subLevelIndex1].subcategories[subLevelIndex2].subcategories.findIndex(category => this.configSettings.cleanUrl(category.name) === subCategoryName3);
                      if(subCategoryName3 && subLevelIndex3) {
                          this.title = response[index].subcategories[subLevelIndex1].subcategories[subLevelIndex2].subcategories[subLevelIndex3].name;
                      }





                  } else if(response[index].subcategories[subLevelIndex1].has_subcategory === 'Yes') {
                      this.categories = response[index].subcategories[subLevelIndex1].subcategories;
                      this.title = response[index].subcategories[subLevelIndex1].name;
                  }
              } else if(response[index].has_subcategory === 'Yes') {
                  this.categories = response[index].subcategories;
                  this.title = response[index].name;
              }*/

              let index = response.findIndex(category => category.id === categoryId);
              if(index === -1) {
                  for(let i = 0; i < response.length; i++) {
                      if(response[i] && response[i].has_subcategory === 'Yes') {
                          index = response[i].subcategories.findIndex(category => category.id === categoryId);
                          if(index === -1) {
                              for(let j = 0; j < response[i].subcategories.length; j++) {
                                  if(response[i].subcategories[j] && response[i].subcategories[j].has_subcategory === 'Yes') {
                                      index = response[i].subcategories[j].subcategories.findIndex(category => category.id === categoryId);
                                      if(index === -1) {
                                          for(let k = 0; k < response[i].subcategories[j].subcategories.length; k++) {
                                              if(response[i].subcategories[j].subcategories[k] && response[i].subcategories[j].subcategories[k].has_subcategory === 'Yes') {
                                                  index = response[i].subcategories[j].subcategories[k].subcategories.findIndex(category => category.id === categoryId);
                                                  if(index === -1) {

                                                  } else {
                                                      this.title = response[i].subcategories[j].subcategories[k].subcategories[index].name;
                                                      this.categories = response[i].subcategories[j].subcategories[k].subcategories[index].subcategories;
                                                  }
                                              }
                                          }
                                      } else {
                                          this.title = response[i].subcategories[j].subcategories[index].name;
                                          this.categories = response[i].subcategories[j].subcategories[index].subcategories;
                                      }
                                  }
                              }
                          } else {
                              this.title = response[i].subcategories[index].name;
                              this.categories = response[i].subcategories[index].subcategories;
                          }
                      }
                  }
              } else {
                  this.title = response[index].name;
                  this.categories = response[index].subcategories;
              }

              this.categories.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
              this.category = categoryId;

              this.doSearch();
          });
      } else if (path.some(e => e.path === 'brand')) {
          this.brands = [];
          const brandId = +this.route.snapshot.paramMap.get('id');
          this.brands.push(brandId);

          this.doSearch();
      } else {
          this.doSearch();
      }
  }

  doSearch() {
      this.configSettings.toggleLoading(true);
      const params = {
          q: (this.q) ? this.q : '',
          lang: this.lang,
          category_id: (this.category) ? this.category : '',
          attribute_id: (this.attributes.length === 1) ? this.attributes : '',
          brand_id: (this.brands.length === 1) ? this.brands : '',
          in_stock: '',
          page: this.page,
          per_page: this.pageSize,
          is_featured: this.isFeatured,
          latest: this.latest,
          best_selling: this.bestSelling,
          sort_by: this.sortBy,
          store: this.store.iso_code,
          price_range: (this.minPrice >= 0 && this.maxPrice > 0) ? this.minPrice + '-' + this.maxPrice : '',
          user_id: this.user.id
      };

      this.params = {
          brand_id: (this.brands.length > 1) ? this.brands.join(',') : '',
          attribute_id: (this.attributes.length > 1) ? this.attributes.join(',') : ''
      };

      this.configService.postRequest('search', params, this.params)
          .subscribe(response => {
              this.products = [];
              if (response.status == 200) {
                  //this.filters = [];

                  //this.maxPrice = response.data.max_product_price;
                  response.data.products.map(products => this.products.push(products));
                  this.totalPages = response.data.total_pages;

                  if (this.filters.length === 0) {
                      //this.filters = [];
                      response.data.filter.map(filter => {
                          this.filterValues = filter.filter_values;

                          if (filter.filter_values.length > 1) {
                              this.filters.push({
                                  name: filter.filter_name,
                                  type: filter.filter_type,
                                  values: this.filterValues
                              });
                          }
                      });

                      this.filters.map(filter => {
                          filter.values.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
                      });
                  }
              }

              this.configSettings.toggleLoading(false);
              this.dataAvailable = true;
              setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
          }, error => { this.configSettings.toggleLoading(false); });
  }

    getAllCategories(): Promise<Category[]> {
        let promise = new Promise<Category[]>((resolve, reject) => {
            this.configSettings.toggleLoading(true);
            const params = {
                lang: this.configSettings.getLang(),
                isWeb: true
            };
            this.configService.readRequest('all-categories', params)
                .subscribe(response => {
                    this.configSettings.toggleLoading(false);
                    resolve(response.body.data);
                }, error => { this.configSettings.toggleLoading(false); });
        });

        return promise;
    }

    expandFilter($event) {
        if ( $event.target.closest('li').classList.contains('active') ) {
            document.querySelector('ul.filters > li.active').classList.remove('active');
        } else {
            const activeElement = document.querySelector('ul.filters > li.active');
            if (activeElement) {
                document.querySelector('ul.filters > li.active').classList.remove('active');
            }
            $event.target.closest('li').classList.add('active');
        }
        return false;
    }

    doFilter($event, value, filter) {
        if (filter && filter === 'category') {
            this.category = value;
            this.selectedCategory = value;
            this.doSearch();
            return false;
        }
        if ($event.target.parentElement.classList.contains('selected')) {
            if (filter && filter === 'brand') {
                this.removeFromArray(this.brands, value);
            } else if (filter && filter === 'category') {
                this.category = null;
            } else if (filter && (filter !== 'brand' && filter !== 'category')) {
                this.removeFromArray(this.attributes, value);
            }

            $event.target.parentElement.classList.remove('selected');
        } else {
            $event.target.parentElement.classList.add('selected');

            if (filter && filter === 'brand') {
                this.brands.push(value);
            }  else if (filter && (filter !== 'brand' && filter !== 'category')) {
                this.attributes.push(value);
            }
        }

        this.doSearch();
        return false;
    }

    clearFilter() {
        this.brands = [];
        this.attributes = [];
        this.minPrice = 0;
        this.maxPrice = 0;
        this.category = null;
        this.selectedCategory = null;
        this.doSearch();
        /**
         * Unselecting all filter checkboxes
         */
        let elements = document.querySelectorAll('.filter-input.selected');
        for(let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('selected');
        }
        return false;
    }

    updatePriceFilter($event) {
      this.getProductList();
      this.toggleFilter();
      return false;
    }

    removeFromArray(arr, key) {
        var index = arr.indexOf(key, 0);
        if (index > -1) {
            arr.splice(index, 1);
        }
    }

    goToPage(page: number) {
      this.page = page;
      this.doSearch();
      return false;
    }

    changePageSize(pageSize: number) {
        this.pageSize = pageSize;
        this.doSearch();
        return false;
    }

    toggleFilter() {
        this.fixedFilter = !this.fixedFilter;
    }
}
