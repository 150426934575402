import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Order} from "../models/order";
import {UserService} from "../config/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "../models/store";
import {ConfigSettings} from "../config/config.settings";
import {ConfigService} from "../config/config.service";
import {MatDialog, MatSnackBar} from "@angular/material";
import {DialogComponent} from "../dialog/dialog.component";
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css'],
  animations: [ listStagger ]
})
export class OrderDetailComponent implements OnInit {
  order: Order = null;
  id: number;
  lang: string;
  userId: string;
  store: Store;
  paymentMethod: string;
  currency: string;
  dataAvailable = false;
  @Output() voted = new EventEmitter<number>();

  constructor(
      private configSettings: ConfigSettings,
      private configService: ConfigService,
      private userService: UserService,
      private route: ActivatedRoute,
      private router: Router,
      private dialog: MatDialog,
      private snackBarRef: MatSnackBar,
      protected footerService: FooterService
  ) { }

  ngOnInit() {
    this.lang = this.configSettings.getLang();
    /* getting store from config*/
    this.configSettings.getStoreObject().then((result) => {
        this.store = <Store> result;
        this.currency = result['currency_' + this.lang];

        if(this.userService.order) {
            this.order = this.userService.order;
            this.userId = this.userService.userId;

            if(this.order.payment_mode === 'C') {
                this.paymentMethod = this.lang == 'en' ? 'Cash on delivery' : 'الدفع عند الاستلام';
            } else if(this.order.payment_mode === 'K') {
                this.paymentMethod = this.lang == 'en' ? 'Knet' : 'كي نت';
            } else if(this.order.payment_mode === 'CC') {
                this.paymentMethod = this.lang == 'en' ? 'Visa/MasterCard' : 'فيزا';
            }

            this.dataAvailable = true;
            setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
        } else {
            this.configSettings.toggleLoading(true);
            this.id = +this.route.snapshot.paramMap.get('id');
            this.userService.getUserSession().then(user => {
                if(user !== false) {
                    this.userId = user.id;
                }
                this.getOrder();
            }, error => { this.configSettings.toggleLoading(false); });
        }
    });
  }

  getOrder() {
      const params = {
          user_id: this.userId,
          lang: this.lang,
          store: this.store.iso_code
      };

      this.configService.readRequest('user-orders', params)
          .subscribe(response => {
              let orders = <Order[]>response.body.data;
              const index = orders.findIndex(order => order.id === this.id);
              this.order = orders[index];

              if(this.order.payment_mode === 'C') {
                  this.paymentMethod = this.lang == 'en' ? 'Cash on delivery' : 'الدفع عند الاستلام';
              } else if(this.order.payment_mode === 'K') {
                  this.paymentMethod = this.lang == 'en' ? 'Knet' : 'كي نت';
              } else if(this.order.payment_mode === 'CC') {
                  this.paymentMethod = this.lang == 'en' ? 'Visa/MasterCard' : 'فيزا';
              }

              this.configSettings.toggleLoading(false);
              this.dataAvailable = true;
              setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
          });
  }

  showCancelModal() {
      let dialogRef = this.dialog.open(DialogComponent, {
          data: {cancelOrder: true, user: this.userId, id: this.order.id },
      });
  }

  requestReturn() {
      window.location.href = 'request-return/' + this.order.id;

      /*if(confirm("Are you sure you want to return all items of this order?")) {
          this.configSettings.toggleLoading(true);
          const params = {
              id: this.order.id,
              user: this.userId
          };

          this.configService.readRequest('request-return', params)
              .subscribe(response => {
                  this.configSettings.toggleLoading(false);
                  if(response.body.status !== 200) {
                      let snackBarRef = this.snackBarRef.open(response.body.message, null, {
                          duration: 5000
                      });
                  } else {
                      this.router.navigate(['my-orders']);
                  }
              });
      }*/
  }
}
