import {EventEmitter, Injectable, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs/index';
import {ReturnRequest} from '../models/return-request';

@Injectable({
  providedIn: 'root'
})
export class RequestReturnsService {
    private returnRequestSource = new BehaviorSubject(null);
    returnRequest = this.returnRequestSource.asObservable();
    constructor() { }

    setReturnRequest(returnRequest: ReturnRequest) {
        this.returnRequestSource.next(returnRequest);
    }

}
