import { Component, OnInit } from '@angular/core';
import {Banner} from '../models/banner';
import {Notification} from '../models/notification';
import {Title} from '@angular/platform-browser';
import {ConfigService} from '../config/config.service';
import {User} from '../models/user';
import {Category} from '../models/category';
import {Product} from '../models/product';
import {ConfigSettings} from "../config/config.settings";
import {NotificationService} from '../config/notification.service';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Store} from '../models/store';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import { UserService } from '../config/user.service';
import {PopularSearchService} from '../config/popular-search.service';
import {Suggestion} from '../models/suggestion';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    animations: [ listStagger ]
})
export class HomeComponent implements OnInit {
    banners: Banner[] = [];
    notifications: Notification[] = [];
    popularSearch: Suggestion[] = [];
    user: User = new User();
    categories: Category[] = [];
    categoriesXs: Category[] = []; // For small devices
    featuredProducts: Product[] = [];
    bestSellers: Product[] = [];
    newArrivals: Product[] = [];
    lang: string;
    currency: string;
    store: Store;
    dataAvailable = false;

    constructor(
        private titleService: Title,
        private configService: ConfigService,
        private notificationService: NotificationService,
        private popularSearchService: PopularSearchService,
        private configSettings: ConfigSettings,
        protected localStorage: LocalStorage,
        protected footerService: FooterService,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.notificationService.currentNotification.subscribe(data => this.notifications = data);
        this.lang = this.configSettings.getLang();


        /**
         * getting store from config
         *  */
        this.configSettings.getStoreObject().then((result) => {
            this.configSettings.toggleLoading(true);
            this.store = <Store> result;
            this.currency = result['currency_' + this.lang];
             /**
             * Getting user session data
             */
            this.userService.getUserSession().then(response => {
                if (response !== false) {
                    this.user = response;
                }
                this.readHomeApi();
            }, error => { this.configSettings.toggleLoading(false); });
        });
    }

    readHomeApi() {
        try {
            const params = {
                'store': this.store.iso_code,
                'user_id': this.user.id
            };

            this.configService.readRequest('home', params)
                .subscribe(response => {
                    if (response.status === 200) {
                        // response.body.data.banners.map(banner => this.banners.push(banner));
                        this.notifications.push({title: response.body.data.notify_text, sub_title: ''});
                        // this.categories.push({image: response.body.data.website_men_image.toString(), name: 'MEN', id: 29});
                        // this.categories.push({image: response.body.data.website_kids_image.toString(), name: 'KIDS', id: 30});
                        this.categoriesXs.push({image: response.body.data.men_image.toString(), name: 'MEN', id: 29});
                        this.categoriesXs.push({image: response.body.data.kids_image.toString(), name: 'KIDS', id: 30});
                        response.body.data.featured.map(featured => this.featuredProducts.push(featured));
                        response.body.data.new_arrivals.map(new_arrivals => this.newArrivals.push(new_arrivals));
                        response.body.data.best_sellers.map(bestSellers => this.bestSellers.push(bestSellers));
                        response.body.data.popular_searches.map(popularSearch => this.popularSearch.push(popularSearch));
                        this.configSettings.toggleLoading(false);

                        /**** Passing popular search from home to header component and adding in local storage ****/
                        this.popularSearchService.changePopularSearch(this.popularSearch);
                        this.localStorage.setItem('popular_searches', this.popularSearch).subscribe(() => {});

                        /**** Passing notification from home to header component and adding in local storage ****/
                        this.notificationService.changeNotification(this.notifications);
                        this.localStorage.setItem('notifications', this.notifications).subscribe(() => {});

                        this.dataAvailable = true;

                        setTimeout(() => {
                            this.footerService.toggleFooter(true);
                        }, 300);
                    } else {
                        console.log(response);
                        this.configSettings.toggleLoading(false);
                    }
                },
            error => {
                     this.configSettings.toggleLoading(false);
                },
            );
        } catch (e) {
            this.configSettings.toggleLoading(false);
        }
    }

    getBannerRoute(banner: Banner) {
        if (banner.link_type == 'C') {
            return '/category/' + banner.link_title.toLowerCase() +'/'+ banner.link_id;
        } else if(banner.link_type == 'P') {
            return '/product/detail/' + banner.link_id;
        } else if(banner.link_type == 'BR') {
            return '/brand/' + banner.link_id;
        } else {
            return banner.url;
        }
    }

}
