import { Component, OnInit } from '@angular/core';
import {NavbarService} from "../config/navbar.service";
import { listStagger } from '../animations';
import {User} from "../models/user";
import {UserService} from "../config/user.service";
import {AuthService} from "angularx-social-login";
import {WishlistService} from "../config/wishlist.service";
import {CartService} from "../config/cart.service";
import {LocalStorage} from "@ngx-pwa/local-storage";
import {MatDialog, MatSnackBar} from "@angular/material";
import { DialogComponent } from '../dialog/dialog.component';
import {NgForm} from "@angular/forms";
import {ConfigSettings} from "../config/config.settings";
import {HttpClient, HttpParams} from "@angular/common/http";

interface MailChimpResponse {
    result: string;
    msg: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  animations: [ listStagger ]
})
export class FooterComponent implements OnInit {
  navigationStatus: boolean = true;
  appStoreLink = 'https://itunes.apple.com/us/app/bazma/id1377566242?ls=1&mt=8';
  playStoreLink  = 'https://play.google.com/store/apps/details?id=com.alcantara.Bazma&hl=en';
  facebookLink = 'https://www.facebook.com/BazmaShop-357456838091478/';
  twitterLink = 'https://twitter.com/BazmaShop';
  instagramLink = 'https://www.instagram.com/bazmashop/';
  snapchatLink = 'https://www.snapchat.com/add/bazmashop';
  user: User;
  email: string;
  validEmail: boolean = false;
  mailChimpEndpoint = 'https://bazmashop.us19.list-manage.com/subscribe/post-json?u=90100d93fc9fc3b60e25a255e&id=ed6bc82bd7&';

  constructor(
      private navbarService: NavbarService,
      protected localStorage: LocalStorage,
      private userService: UserService,
      private authService: AuthService,
      private wishlistService: WishlistService,
      private cartService: CartService,
      private dialog: MatDialog,
      private configSettings: ConfigSettings,
      private http: HttpClient
  ) { }

  ngOnInit() {
      this.navbarService.change.subscribe(status => {this.navigationStatus = status; console.log(this.navigationStatus);});
      this.userService.getUserSession().then(response => {
          if(response !== false) {
              this.user = response;
          }
      });
  }

    showLogoutModal() {
        let dialogRef = this.dialog.open(DialogComponent, {
            data: {logout: true, user: this.user },
        });
    }

    onSubmit(emailForm: NgForm) {
      if(this.email) {
          this.registerForNewsletter();
          emailForm.reset();
      }
    }

    registerForNewsletter() {
        this.configSettings.toggleLoading(true);
        const params = new HttpParams()
            .set('NAME', '')
            .set('EMAIL', this.email)
            .set('b_123abc123abc123abc123abc123abc123abc', '');

        const mailChimpUrl = this.mailChimpEndpoint + params.toString();

        this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(
            response => {
                this.configSettings.toggleLoading(false);
        }, error => {
                this.configSettings.toggleLoading(false);
        });
    }

    onChange(newValue) {
        const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (validEmailRegEx.test(newValue)) {
            this.validEmail = true;
        }else {
            this.validEmail = false;
        }
    }
}
