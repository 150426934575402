import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import {ConfigSettings} from '../config/config.settings';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  constructor(
    private configSettings: ConfigSettings,
    private router: Router
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('request intercepted successfully!');
      const authReq = req.clone({
        headers: req.headers.set('authtoken', '3decee0c56ec4441b975680877cc150bf2e289f0a4e45f71018c28755106d940')
      });
      // console.log('Sending request with new header now ...');
      return next.handle(authReq).catch((error, caught) => {
        //console.log('Error Occurred');
        //console.log(error);
        return Observable.throw(error);
      }) as any;

  }
}
