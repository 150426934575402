import {EncryptionService} from 'angular-encryption-service';
import {ConfigSettings} from './config.settings';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CryptService {
    constructor(public encryptionService: EncryptionService) {}

    doEncrypt(plainText: string): Promise<string> {
        return this.encryptionService.generateKey(ConfigSettings.ENCRYPT_KEY).then(key => {
            return this.encryptionService.encrypt(plainText, key);
        });
    }

    doDecrypt(encryptedText: string): Promise<string> {
        return this.encryptionService.generateKey(ConfigSettings.ENCRYPT_KEY).then(key => {
            return this.encryptionService.decrypt(encryptedText, key);
        });
    }
}
