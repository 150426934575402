import {AfterViewInit, Component, HostListener, Input, OnInit} from '@angular/core';
import {NavbarService} from "../config/navbar.service";
import {Category} from '../models/category';
import {ConfigSettings} from '../config/config.settings';
import {ConfigService} from '../config/config.service';
import {Faq} from '../models/faq';
import {forEach} from '@angular/router/src/utils/collection';
import {Store} from '../models/store';
import {LocalStorage} from "@ngx-pwa/local-storage";
import {NotificationService} from '../config/notification.service';
import {Notification} from '../models/notification';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Brands} from "../models/brands";
import {FormBuilder, FormGroup} from "@angular/forms";
import {debounceTime, finalize, map, switchMap, tap, throttleTime} from "rxjs/operators";
import {CartService} from "../config/cart.service";
import {Suggestion} from "../models/suggestion";
import { listStagger } from '../animations';
import {Subject} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {PopularSearchService} from '../config/popular-search.service';

declare var $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    animations: [ listStagger ]
})
export class HeaderComponent implements OnInit, AfterViewInit {
    overlayHeight = '0px';
    sideMenuWidth = '0px';
    isSideMenuOpen = false;
    isStoreSwitcherOpen = false;
    navigationStatus: boolean = true;
    categories: Category[] = [];
    @Input() stores: Store[] = [];
    @Input() store: Store;
    hiddenOverlay: boolean = true;
    notifications: Notification[] = [];
    lang: string;
    q: string;
    isLoading: boolean = false;
    searchForm: FormGroup;
    suggestions: Suggestion[] = [];
    recentSearches: Suggestion[] = [];
    popularSearches: Suggestion[] = [];
    resize = new Subject();
    constructor(
        private navbarService: NavbarService,
        private configService: ConfigService,
        public configSettings: ConfigSettings,
        private notificationService: NotificationService,
        private popularSearchService: PopularSearchService,
        protected localStorage: LocalStorage,
        private translate: TranslateService,
        private route: ActivatedRoute,
        public router: Router,
        private formBuilder: FormBuilder,
        private cartService: CartService,
        private http: HttpClient
    ) {
        this.resize.asObservable().pipe(
            throttleTime(500)
        ).subscribe(data => {
            this.setWrapperHeight();
        })
    }

    ngOnInit() {
        this.navbarService.change.subscribe(status => {
            this.navigationStatus = status;

            if(!this.navigationStatus) {
                setTimeout(this.setWrapperHeight, 300);
            }
        });
        this.getRecentSearch();
        this.readAllCategories();
        this.readNotifications();
        this.readPopularSearch();

        this.lang = this.configSettings.getLang();

        this.searchForm = this.formBuilder.group({
            q: null
        });

        this.searchForm.get('q').valueChanges.pipe(
            debounceTime(300),
            tap(() => this.isLoading = true),
            switchMap(value => this.cartService.search({q: value.toString()})
                .pipe(
                    finalize(() => this.isLoading = false),
                )
            )
        ).subscribe(results => {
            if (results.body.status === 200 && results.body.data.length) {
                this.suggestions = results.body.data;
            }
        });
    }

    ngAfterViewInit() {
        /* Setting padding top for main page wrapper */
        this.setWrapperHeight();
    }

    displayFn(query: Suggestion) {
        if (query) {
            return query.name
        }
    }

    optionSelected($event) {
        if ($event) {
            this.doBlur();
            this.closeSearch();
            /**
             * Add selected option to local recent_search
             */
            this.localStorage.getItem<any>('recent_search').subscribe((recent_search) => {
                let recentSearchArr = [];
                if (recent_search) {
                    const index = recent_search.findIndex(recent_search => recent_search.id === $event.id);
                    //console.log('Search index: ' + index); return false;
                    recentSearchArr = recent_search;
                    if(index < 0) {
                        recentSearchArr.push($event);
                    }
                } else {
                    recentSearchArr = [$event];
                }
                this.localStorage.setItem('recent_search', recentSearchArr).subscribe((result) => {
                    this.recentSearches = <Suggestion[]> recentSearchArr;
                    if ($event.type === "C") {
                        // this.router.navigate(['/category/', this.configSettings.cleanUrl($event.name), $event.id]);
                        window.location.href = '/category/' + this.configSettings.cleanUrl($event.name) + '/' + $event.id;
                    } else if($event.type === "P") {
                        // this.router.navigate(['/product/detail/', $event.id]);
                        window.location.href = '/product/detail/' + $event.id;
                    } else if($event.type === "B" || $event.type === "BR") {
                        // this.router.navigate(['/brand/', this.configSettings.cleanUrl($event.name), $event.id]);
                        window.location.href = '/brand/' + this.configSettings.cleanUrl($event.name) + '/' + $event.id;
                    }
                });
            
            });
        }
    }

    setWrapperHeight() {
        const height = document.getElementById("page-header").clientHeight;
        document.getElementById("wrapper").style.paddingTop = height + 'px';
    }

    getRecentSearch() {
        this.localStorage.getItem<any>('recent_search').subscribe((recent_search) => {

            if (recent_search) {
                this.recentSearches = <Suggestion[]> recent_search;
            }
        });
    }

    changeLanguage(event: any) {
        const newLang = (this.lang === 'ar') ? 'en' : 'ar';
        this.configSettings.setLang(newLang);
        /* Removing notification stored in local storage */
        this.localStorage.removeItem('notifications').subscribe(() => {
            window.location.href = '/';
        });
    }

    doFocus() {
        document.querySelector('nav.header-nav').classList.add('search-active');
    }

    doBlur() {
        document.querySelector('nav.header-nav').classList.remove('search-active');
    }

    hideSearch(event: any) {
        /* Do not hide  if user has clicked inside the dropdown */
        var element = document.getElementById('mat-autocomplete-1');
        if (element) {
            if(!element.contains(event.target)) {
                document.querySelector('nav.header-nav').classList.remove('search-active');
            }
        }
    }

    clearRecentSearchHistory() {
        this.isLoading = true;
        this.localStorage.removeItem('recent_search').subscribe(() => {
            this.recentSearches = [];
            this.isLoading = false;
        }, error => { this.isLoading = false; });
    }

    openSearch() {
        this.overlayHeight = '100%';
        return false;
    }

    closeSearch() {
        this.overlayHeight = '0px';
        return false;
    }

    openNav() {
        if (!this.isSideMenuOpen) {
            this.isSideMenuOpen = true;
            this.sideMenuWidth = '280px';
            document.querySelector('body').classList.add('menu-open');
        } else {
            this.isSideMenuOpen = false;
            this.sideMenuWidth = '0px';
            document.querySelector('body').classList.remove('menu-open');
        }
        return false;
    }

    openStoreSwitcher() {
        if(!this.isStoreSwitcherOpen){
            this.isStoreSwitcherOpen = true;
            document.querySelector('body').classList.add('store-switcher-open');
            /**
             * Removing sidemenu
             */
            this.isSideMenuOpen = false;
            this.sideMenuWidth = '0px';
            document.querySelector('body').classList.remove('menu-open');
        } else {
            this.isStoreSwitcherOpen = false;
            document.querySelector('body').classList.remove('store-switcher-open');
        }
        return false;
    }

    toggleMenu(event: any) {
        $('#menus ul.main-nav').hide();
        $('#' + event.target.target).show();
        $('.top-category .active').removeClass('active');
        $('#' + event.target.id).addClass('active');
    }

    readAllCategories() {
        const params = {
            lang: this.lang,
            isWeb: true
        };
        this.configService.readRequest('all-categories', params)
            .subscribe(response => {
                if (response.body.data) {
                    this.categories =  <Category[]> response.body.data;
                }
            });
    }

    readNotifications() {
        this.notificationService.currentNotification.subscribe(data => this.notifications = data);
        this.localStorage.getItem<any>('notifications').subscribe((notifications) => {
            if (notifications) {
                this.notifications = <Notification[]> notifications;
            } /*else {
                this.notificationService.currentNotification.subscribe(data => this.notifications = data);
            }*/
        });
    }

    readPopularSearch() {
        // this.popularSearchService.popularSearch.subscribe(data => this.popularSearches = data);
        this.localStorage.getItem<any>('popular_searches').subscribe((popular_searches) => {
            if (popular_searches) {
                this.popularSearches = <Suggestion[]> popular_searches;
            } else {
                this.popularSearchService.popularSearch.subscribe(data => this.popularSearches = data);
            }
        });
    }

    toggleOverlay(event: any) {
        this.hiddenOverlay = !this.hiddenOverlay;
    }

    hideOverlay(event: any) {
        /* Do not hide overlay if user has clicked inside the dropdown*/
        var element = document.getElementById('store-change-dropdown');
        if(!element.contains(event.target)) {
            this.hiddenOverlay = true;
        }
    }

    changeStore (event: any, id: number) {
        const index = this.stores.findIndex(store => store.id === id);
        var selectedStore = this.stores[index];
        this.setLocalStore(selectedStore);
        window.location.reload();
    }

    onSubmit() {
        if(this.q) {
            window.location.href = '/search?q='+this.q;
        }
    }

    openSideMenu(id) {
        if(document.getElementById(id).classList.contains('expand')) {
            document.getElementById(id).classList.remove('expand');

            let children = document.getElementById(id).querySelectorAll('.expand');
            for(let i = 0; i < children.length; i++) {
                children[i].classList.remove('expand');
            }
        } else {
            let siblings = document.getElementById(id).parentElement.parentElement.querySelectorAll('.expand');
            for(let i = 0; i < siblings.length; i++) {
                siblings[i].classList.remove('expand');
            }
            document.getElementById(id).classList.add('expand');

            let siblingUl = document.getElementById(id).querySelectorAll('ul');
            if(!siblingUl.length) {
                this.openNav();
                return true;
            }
        }

        return false;
    }

    openSubNav(id, $event) {
        let nav = document.querySelectorAll('ul.main-nav');
        for(let i = 0; i < nav.length; i++) {
            nav[i].classList.remove('top-active');
        }

        document.getElementById(id).classList.add('top-active');

        document.querySelector('.side-menu-top-category').querySelector('.active').classList.remove('active');
        $event.target.classList.add('active');
        return false;
    }

    @HostListener('window:resize', ['$event.target'])
    onresize(target) {
        this.resize.next(target.outerWidth);
    }

    setLocalStore (selectedStore: any) {
        this.localStorage.setItem('store_object', selectedStore).subscribe(() => {});
    }

}
