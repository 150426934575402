import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigSettings } from './config/config.settings';
import { FooterService } from './config/footer.service';
import { Store } from "./models/store";
import { ConfigService } from "./config/config.service";
import { LocalStorage } from "@ngx-pwa/local-storage";
import SmartBanner from 'smart-app-banner';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { ZendeskConfig } from './app.module';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit,AfterViewInit {
    lang: string;
    footerStatus: boolean;
    stores: Store[] = [];
    store: Store;

    public constructor(
        private translate: TranslateService,
        private configSettings: ConfigSettings,
        private configService: ConfigService,
        private footerService: FooterService,
        protected localStorage: LocalStorage,
        private _NgxZendeskWebwidgetService: NgxZendeskWebwidgetService
    ) {
        this.readAllStores();
        translate.addLangs(['en', 'ar']);
        translate.setDefaultLang('en');
        const lang = this.configSettings.getLang();
        if (lang) {
            translate.use(lang);
        } else {
            translate.use('ar');
        }
        this.lang = translate.currentLang;
        this.configSettings.setLang(translate.currentLang);

        this.footerService.footerStatus.subscribe(data => this.footerStatus = data);

        
    }

    logout() {
        this._NgxZendeskWebwidgetService.zE('webWidget', 'hide');
    }

    async ngOnInit() {
        await this._NgxZendeskWebwidgetService.initZendesk(new ZendeskConfig());
        this._NgxZendeskWebwidgetService.zE('webWidget', 'show');
    }

    ngAfterViewInit() {

        new SmartBanner({
            daysHidden: 15,   // days to hide banner after close button is clicked (defaults to 15)
            daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
            appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
            title: 'Bazmashop',
            author: 'BAZMASHOP.COM',
            button: 'VIEW',
            store: {
                ios: 'On the App Store',
                android: 'In Google Play',
                windows: 'In Windows store'
            },
            price: {
                ios: 'FREE',
                android: 'FREE',
                windows: 'FREE'
            },
            // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
            // , icon: '' // full path to icon image if not using website icon image
            // , force: 'ios' // Uncomment for platform emulation
        });
    }

    readAllStores() {
        this.configService.readRequest('stores', {})
            .subscribe(response => {
                if (response.body.data) {
                    this.stores = <Store[]>response.body.data;

                    this.localStorage.getItem<any>('store_object').subscribe((store_object) => {
                        /*
                        * If Local Storage is set
                        * then set store object
                        * else
                        * Set kuwait as default store
                        * */
                        if (store_object) {
                            this.store = <Store>store_object;
                            //console.log(this.store.iso_code);
                            this.configSettings.setStore(this.store.iso_code);
                        } else {
                            this.findCountry().then(response => {
                                let isoCode = "KW";
                                if (response.countryCode) {
                                    isoCode = response.countryCode;
                                }
                                this.getCountryDetails(isoCode).then(store => {
                                    if (store.status === 200) {
                                        var selectedStore = store.data;
                                        this.setLocalStore(selectedStore);
                                        this.store = <Store>selectedStore;
                                        this.configSettings.setStore(this.store.iso_code);
                                    } else {
                                        let index = this.stores.findIndex(store => store.iso_code === "KW");
                                        let selectedStore = this.stores[index];
                                        this.setLocalStore(selectedStore);
                                        this.store = <Store>selectedStore;
                                        this.configSettings.setStore(this.store.iso_code);
                                    }
                                });
                            });
                        }
                        /* Removing selected store from stores array*/
                        //const index = this.stores.findIndex(store => store.id === this.store.id);
                        //this.stores.splice(index, 1);
                    });
                }
            });
    }

    findCountry(): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            this.configService.readRequest('ip-info', {})
                .subscribe(response => {
                    if (response.body.data) {
                        resolve(response.body.data);
                    }
                });
        });

        return promise;
    }

    getCountryDetails(iso_code): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            const params = {
                iso_code: iso_code
            };
            this.configService.readRequest('country-details', params)
                .subscribe(response => {
                    if (response.body.data) {
                        resolve(response.body);
                    }
                });
        });

        return promise;
    }

    setLocalStore(selectedStore: any) {
        this.localStorage.setItem('store_object', selectedStore).subscribe(() => { });
    }
}
