import { Component, OnInit } from '@angular/core';
import {HttpParams} from "@angular/common/http";

declare var branch: any;

@Component({
  selector: 'app-deep-link',
  template: '',
  styleUrls: ['./deep-link.component.css']
})
export class DeepLinkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.registerBranch();
      // branch.init('key_live_cczY5787HY1iXKOjYwyoZnipAqaSKBze', function(err, data) { // key_test_ndzZ2665S95j2JJpWDtCAhikurjUHvrz
      branch.init('key_live_nm268a5wUv1i0q3nvBCogecgzAg6uNec', function(err, data) { // key_test_ndzZ2665S95j2JJpWDtCAhikurjUHvrz
          const target = data.data_parsed['target'];
          const id = data.data_parsed['target_id'];
          const name = data.data_parsed['$og_title'].trim().toLowerCase().replace(/[^a-zA-Z\d\s]/,'').replace(' ','-');

          if(target === 'P') {
            window.location.href = "/product/detail/"+id;
          } else if(target === 'CL') {
            window.location.href = "/category/" + name + "/" + id;
          } else if(target === 'B' || target === 'BR') {
            window.location.href = "/brand/" + name + "/" + id
          }
      });
  }

  registerBranch() {
      this.registerBranchSource(window, document, "script", "branch", function (b, r) { b[r] = function () { b._q.push([r, arguments]); }; }, { _q: [], _v: 1 }, "addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);
  }

  registerBranchSource(b, r, a, n, c, h, _, s) {
      if (!b[n] || !b[n]._q) {
          for (; s < _.length;)
              c(h, _[s++]);
          let d = r.createElement(a);
          d.async = 1;
          d.src = "https://cdn.branch.io/branch-latest.min.js";
          let k = r.getElementsByTagName(a)[0];
          k.parentNode.insertBefore(d, k);
          b[n] = h;
      }
  }

}
