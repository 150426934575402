import {Component, Input, OnInit} from '@angular/core';
import {Cart} from "../models/cart";
import {Address} from "../models/address";
import {CheckoutService} from "../config/checkout.service";
import {first} from "rxjs/operators";
import {Router} from "@angular/router";
import {NavbarService} from "../config/navbar.service";
import {ConfigSettings} from "../config/config.settings";
import {UserService} from "../config/user.service";
import {Product} from "../models/product";
import {DeliveryOptions} from "../models/delivery-options";
import {PaymentOptions} from "../models/payment-options";
import {Order} from "../models/order";
import { Store } from '../models/store';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import { User } from '../models/user';
import {ConfigService} from "../config/config.service";
import {forEach} from "@angular/router/src/utils/collection";
import {Item} from "../models/item";

declare var ackoo: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
  animations: [ listStagger ]
})
export class PaymentComponent implements OnInit {
    cart: Cart;
    @Input() address: Address;
    currency: string = '';
    cartValue: number = 0;
    shippingCost: string = '';
    discount: number = 0;
    selectedDelivery: DeliveryOptions;
    selectedPayment: PaymentOptions;
    deliveryOptions: DeliveryOptions[] = [];
    paymentOptions: PaymentOptions[] = [];
    order: Order;
    vatCharges: number = 0;
    codCost: number = 0;
    lang: string;
    store: Store;
    promo_code: string = '';
    user: User;
    promoValidationMessage: string;
    promotionApplied:boolean = false;
    coupon: any;
    showPaymentError = false;
    is_free_delivery = false;

  constructor(
      private checkoutService: CheckoutService,
      private router: Router,
      private navbarService: NavbarService,
      private configSettings: ConfigSettings,
      public configService: ConfigService,
      private userService: UserService,
      protected footerService: FooterService
  ) { }

  ngOnInit() {
    this.navbarService.toggleMainNavigation(false);
    this.checkoutService.setStep(2);
    this.lang = this.configSettings.getLang();

    if(this.checkoutService.address && this.checkoutService.cart) {
        this.address = this.checkoutService.address;
        this.vatCharges = +this.address.vat_charges;
        this.cart = this.checkoutService.cart;

        if (this.checkoutService.address.delivery_options.length) {
            this.shippingCost = this.checkoutService.address.delivery_options[0].price;
            if(this.shippingCost == '0'){
                this.is_free_delivery = true;
            }
            this.selectedDelivery = this.checkoutService.address.delivery_options[0];
        }

        this.deliveryOptions = this.address.delivery_options;

        this.configSettings.getStoreObject().then((result) => {
            this.store = <Store> result;
            this.currency = result['currency_' + this.lang];
            this.checkItemStock();
        });
    } else {
        this.router.navigate(['cart']);
    }
  }

    checkItemStock() {
        this.configSettings.toggleLoading(true);
        this.userService.getUserSession().then(response => {
            if (response !== false) {
                this.user = <User> response;
                this.checkoutService.checkItemStock(response.id, this.cart.items, this.store.iso_code, this.address.address_id).then( data => {
                    if(data.is_coupon_applied === 1){
                        this.coupon = data.coupon;
                        this.promotionApplied = true;
                        this.discount = data.discount_price;
                    } else {
                        this.promotionApplied = false;
                    }
                    this.cart = data.cart.items;
                    this.cartValue = data.sub_total;
                    this.deliveryOptions = <DeliveryOptions[]> data.delivery_options;
                    //
                    this.paymentOptions = data.payment_types;
                    this.paymentOptions.map(payment => {
                       if (payment.code === 'C') {
                           payment.is_enable = this.address.is_cod_enable.toString();
                       }
                    });
                    this.address.cod_cost = data.default_address.cod_cost;

                    this.sendGTagEvent('begin_checkout', this.cart, null);

                    this.configSettings.toggleLoading(false);
                    setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
                }, error => { this.configSettings.toggleLoading(false); });
            } else {
                this.configSettings.toggleLoading(false);
                setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
            }
        }, error => { this.configSettings.toggleLoading(false); });
    }

    doPromotion(): Promise<any> {
        const promise = new Promise<any>((resolve, reject) => {
            const  getParams = {
                lang: this.lang,
                store: this.store.iso_code
            };
            const postParams = {
                user_id: this.user.id,
                order_id: this.configSettings.getCartId(),
                coupon_code: this.promo_code,
                shipping_address_id: this.address.address_id
            };
            this.checkoutService.redeemCoupon(getParams, postParams)
                .pipe(first())
                .subscribe(
                    response => {
                        console.log(response);
                        if (response.status === 200) {
                            const responseData = response.data;
                            this.coupon = responseData.coupon;
                            this.discount = responseData.discount_price;
                            this.shippingCost = responseData.delivery_charges;
                            if(this.shippingCost == '0'){
                                this.is_free_delivery = true;
                            } else {
                                this.is_free_delivery = false;
                            }
                            this.cartValue = responseData.sub_total;
                            this.deliveryOptions = <DeliveryOptions[]> responseData.delivery_options;
                            resolve(true);
                        } else {
                            this.promoValidationMessage = (this.lang === 'en') ? 'UNFORTUNATELY THIS PROMO CODE IS INVALID. PLEASE CHECK AND TRY AGAIN.' : 'لا شك أن هذا الرمز الترويجي غير صالح. يرجى مراجعة وحاول مرة أخرى.';
                            setTimeout(() => { this.promoValidationMessage = ''; }, 5000);
                            this.configSettings.toggleLoading(false);
                            resolve(false);
                        }
                    }, error => { this.configSettings.toggleLoading(false); }
                );

        });
        return promise;
    }

    applyPromotion() {
        this.doPromotion().then((result) => {
            if (result) {
                this.promotionApplied = true;
            }
        });
    }

    removePromotion() {
        this.promo_code = '';
        this.doPromotion().then((result) => {
            if (result) {
                this.promotionApplied = false;
            }
        });
    }


    selectDelivery(delivery, index) {
        // document.querySelector('.address-select.active').classList.remove('active');
        // document.querySelector('#'+delivery).classList.add('active');
        this.selectedDelivery = this.deliveryOptions[index];
        this.shippingCost = this.deliveryOptions[index].price;
    }

    selectpayment($payment, index) {
        this.selectedPayment = this.paymentOptions[index];

        if(this.selectedPayment.code === "C") {
            this.codCost = +this.address.cod_cost;
        } else {
            this.codCost = 0;
        }

        this.showPaymentError = false;
    }

    proceedToCheckout() {
        if (!this.selectedPayment) {
            this.showPaymentError = true;
            return false;
        }
        this.checkoutService.setAddress(this.address);
        this.checkoutService.setCart(this.cart);
        this.checkoutService.setDelivery(this.selectedDelivery);
        this.checkoutService.setPayment(this.selectedPayment);

        this.configService.sendGTagEvent('set_checkout_option', {
            "checkout_step": 2,
            "checkout_option": "delivery method",
            "value": this.selectedDelivery.name
        });

        this.configService.sendGTagEvent('set_checkout_option', {
            "checkout_step": 2,
            "checkout_option": "payment method",
            "value": this.selectedPayment.type
        });

        this.sendGTagEvent('checkout_progress', this.cart, null);

        this.doCheckout();
    }

    doCheckout() {
        this.configSettings.toggleLoading(true);
        this.userService.getUserSession().then(response => {
            if (response !== false) {
                this.checkoutService.checkout(response.id, this.address.address_id, this.selectedDelivery.id, this.selectedPayment.code, this.store.iso_code).then( data => {
                    if (data.payment_url) {
                        // this.configSettings.toggleLoading(false);
                        window.location.href = data.payment_url;
                    } else {
                        this.order = {
                            items: data.cart.items,
                            cod_cost: data.cod_cost,
                            baseCurrencyName: data.baseCurrencyName,
                            delivery_charges: data.delivery_charges,
                            created_date: data.order_details.order_date,
                            order_number: data.order_details.order_number,
                            status: data.order_details.order_status,
                            shipping_address: data.shipping_address,
                            status_color: data.order_details.status_color,
                            sub_total: data.sub_total,
                            total: data.total,
                            vat_charges: data.vat_charges,
                            vat_pct: data.vat_pct,
                            discount_price: (data.discount_price > 0) ? data.discount_price : 0,
                            grand_total_kwd: data.grand_total_kwd
                        };

                        this.checkoutService.setOrderDetails(this.order);
                        this.checkoutService.setPayment(this.selectedPayment);
                        
                        /*this.sendGTagEvent('purchase', data.cart.items, {
                            //"transaction_id": "24.031608523954162",
                            //"affiliation": "Google online store",
                            "value": data.sub_total,
                            "currency": this.selectedPayment.code,
                            "tax": data.vat_charges,
                            "shipping": data.delivery_charges,
                        });*/
                        
                        // ackoo integration
                        // ackoo.trackCheckout({
                        //     orderId: this.order.order_number.toString(),
                        //     amount: Number(this.order.grand_total_kwd),
                        //     currency: "kwd",
                        // });

                        this.configSettings.toggleLoading(false);
                        this.router.navigate(['order-confirmation']);
                    }
                });
            } else {
                this.configSettings.toggleLoading(false);
            }
        }, error => { this.configSettings.toggleLoading(false); });
    }

    sendGTagEvent(event, items: any, additional_params: any) {
        const tmp = [];
        items.forEach(function(item, key) {
            const final_price = item.final_price_kwd * 3.30;
            tmp.push({
                id: item.SKU,
                name: item.name,
                list_name: "Product Details",
                brand: item.brand_name,
                category: item.brand_name,
                variant: "",
                list_position: 1,
                quantity: item.quantity,
                price: final_price.toFixed(2)
            });
        });

        this.configService.sendGTagEvent(event, {
            // additional_params,
            items: tmp,
            coupon: ''
        });
    }
}


