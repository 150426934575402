import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {ProductDetailComponent} from './product-detail/product-detail.component';
import {ProductListComponent} from './product-list/product-list.component';
import {CartComponent} from "./cart/cart.component";
import {AuthGuard} from "./_guard/auth.guard";
import {ShippingAddressComponent} from "./shipping-address/shipping-address.component";
import {CmsComponent} from './cms/cms.component';
import {FaqComponent} from './faq/faq.component';
import {ShippingAddressFormComponent} from "./shipping-address-form/shipping-address-form.component";
import {PaymentComponent} from "./payment/payment.component";
import {OrderConfirmationComponent} from "./order-confirmation/order-confirmation.component";
import {MyAccountComponent} from "./my-account/my-account.component";
import {ChangePasswordComponent} from "./change-password/change-password.component";
import {MyOrdersComponent} from "./my-orders/my-orders.component";
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {OrderDetailComponent} from "./order-detail/order-detail.component";
import {MyAddressComponent} from "./my-address/my-address.component";
import {AddressComponent} from "./address/address.component";
import {WishlistComponent} from "./wishlist/wishlist.component";
import {BrandListComponent} from "./brand-list/brand-list.component";
import {DeepLinkComponent} from "./deep-link/deep-link.component";
import {ReturnRequestListComponent} from './return-request-list/return-request-list.component';
import {RequestReturnComponent} from './request-return/request-return.component';
import {ReturnRequestDetailComponent} from './return-request-detail/return-request-detail.component';

const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full'},
    { path: 'home', component: HomeComponent},
    { path: 'product/detail/:id', component: ProductDetailComponent },
    { path: 'product', component: ProductListComponent },
    { path: 'shop/featured', component: ProductListComponent },
    { path: 'shop/new-arrivals', component: ProductListComponent },
    { path: 'shop/best-sellers', component: ProductListComponent },
    { path: 'category/:name/:id', component: ProductListComponent },
    { path: 'category/:name/:name_2/:id', component: ProductListComponent },
    { path: 'category/:name/:name_2/:name_3/:id', component: ProductListComponent },
    { path: 'category/:name/:name_2/:name_3/:name_4/:id', component: ProductListComponent },
    { path: 'search', component: ProductListComponent },
    { path: 'login', component: LoginComponent},
    { path: 'cart', component: CartComponent},
    { path: 'shipping-address/add', component: ShippingAddressFormComponent, canActivate: [AuthGuard]},
    { path: 'shipping-address/:id/edit', component: ShippingAddressFormComponent, canActivate: [AuthGuard]},
    { path: 'shipping-address', component: ShippingAddressComponent, canActivate: [AuthGuard]},
    { path: 'payment', component: PaymentComponent, canActivate: [AuthGuard]},
    { path: 'order-confirmation', component: OrderConfirmationComponent, canActivate: [AuthGuard]},
    { path: 'about-us', component: CmsComponent, data: {id: 1} },
    { path: 'terms-and-conditions', component: CmsComponent, data: {id: 2} },
    { path: 'return-policy', component: CmsComponent, data: {id: 4} },
    { path: 'privacy-policy', component: CmsComponent, data: {id: 5} },
    { path: 'delivery-terms', component: CmsComponent, data: {id: 7} },
    { path: 'customer-service', component: CmsComponent, data: {id: 8} },
    { path: 'how-to-order', component: CmsComponent, data: {id: 9} },
    { path: 'faq', component: FaqComponent },
    { path: 'my-account', component: MyAccountComponent, canActivate: [AuthGuard]},
    { path: 'my-orders', component: MyOrdersComponent, canActivate: [AuthGuard]},
    { path: 'order/detail/:id', component: OrderDetailComponent, canActivate: [AuthGuard]},
    { path: 'my-address', component: MyAddressComponent, canActivate: [AuthGuard]},
    { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard]},
    { path: 'forgot-password', component: ForgotPasswordComponent},
    { path: 'address/add', component: AddressComponent, canActivate: [AuthGuard]},
    { path: 'address/edit/:id', component: AddressComponent, canActivate: [AuthGuard]},
    { path: 'wishlist', component: WishlistComponent, canActivate: [AuthGuard]},
    { path: 'brands', component: BrandListComponent},
    { path: 'brand/:name/:id', component: ProductListComponent },
    { path: 'deep-link', component: DeepLinkComponent },
    { path: 'return-request-list', component: ReturnRequestListComponent, canActivate: [AuthGuard] },
    { path: 'return-request/detail/:id', component: ReturnRequestDetailComponent, canActivate: [AuthGuard]},
    { path: 'request-return/:id', component: RequestReturnComponent, canActivate: [AuthGuard] },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
      RouterModule
  ]
})
export class AppRoutingModule { }
