import { Component, OnInit } from '@angular/core';
import {Store} from '../models/store';
import {User} from '../models/user';
import {ReturnRequest} from '../models/return-request';
import {FooterService} from '../config/footer.service';
import {ConfigSettings} from '../config/config.settings';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../config/user.service';
import {ConfigService} from '../config/config.service';
import {RequestReturnsService} from '../config/request-returns.service';
import {listStagger} from '../animations';

@Component({
  selector: 'app-return-request-detail',
  templateUrl: './return-request-detail.component.html',
  styleUrls: ['./return-request-detail.component.css'],
  animations: [listStagger]
})
export class ReturnRequestDetailComponent implements OnInit {
  dataAvailable = false;
  lang: string;
  store: Store;
  currency: string;
  user: User;
  request: ReturnRequest;

  constructor(
      private route: ActivatedRoute,
      private configSettings: ConfigSettings,
      private configService: ConfigService,
      protected footerService: FooterService,
      private userService: UserService,
      private requestReturnsService: RequestReturnsService
  ) { }

  ngOnInit() {
      this.lang = this.configSettings.getLang();
      /* getting store from config*/
      this.configSettings.getStoreObject().then((result) => {
          this.store = <Store> result;
          this.currency = result['currency_' + this.lang];

          this.userService.getUserSession().then(user => {
              if (user !== false) {
                  this.user = <User> user;
                  this.getRequestDetails();
              }
          });
      });
  }

  getRequestDetails() {
      this.requestReturnsService.returnRequest.subscribe((data) => {
        if (data) {
            this.request = data;
            this.dataAvailable = true;
            setTimeout(() => {
                this.footerService.toggleFooter(true);
            }, 300);
        } else {
          window.location.href = 'return-request-list';
        }
      });
  }

}
