import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ConfigService} from '../config/config.service';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import { ConfigSettings } from '../config/config.settings';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.css'],
  animations: [ listStagger ]
})
export class CmsComponent implements OnInit {
  id: number;
  lang: string;
  page: string;
  content: string;
  dataAvailable = false;

  constructor(
      private configService: ConfigService,
      private route: ActivatedRoute,
      protected footerService: FooterService,
      private configSettings: ConfigSettings
  ) { }

  ngOnInit() {
      this.id = this.route.snapshot.data['id'];
      this.lang = this.configSettings.getLang();
      this.gatData();
  }

  gatData() {
      const params = {
          page: this.id,
          lang: this.lang
      };
      this.configService.readRequest('cms', params)
          .subscribe(response => {
              this.page = response.body.data.page;
              this.content = response.body.data.content;
              this.dataAvailable = true;
              setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
          });
  }


}
