import {Component, Input, OnInit} from '@angular/core';
import {Product} from '../models/product';
import {Store} from "../models/store";
import {ConfigSettings} from "../config/config.settings";
import {ConfigService} from "../config/config.service";
import {UserService} from "../config/user.service";
import { WishlistService } from '../config/wishlist.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.css']
})
export class ProductItemComponent implements OnInit {
  @Input() item: Product;
  @Input() currency: string;
  @Input() enableRemoveWishlist: boolean = false;
  @Input() lang: string;
  @Input() store: Store;
  @Input() userId: number;
  @Input() returnUrl: string;
  in_wishlist = false;
  constructor(
      private configSettings: ConfigSettings,
      private configService: ConfigService,
      private userService: UserService,
      private wishlistService: WishlistService
  ) { }

  ngOnInit() {
      this.in_wishlist = (this.item.item_in_wishlist == 0) ? false : true;
  }

  toggleWishlist(){
    if(!this.userId){
        if(this.returnUrl){
            window.location.href  = '/login?returnUrl='+this.returnUrl;
        } else {
            window.location.href  = '/login';
        }
        return false;
    }
    if(this.in_wishlist){
        //remove from wishlist
        this.removeFromWishlist();
    } else {
        //add to wishlist
        this.addToWishlist();
    }
  }

  removeFromWishlist() {
    //this.configSettings.toggleLoading(true);
    const getParams = {
        lang: this.lang,
        store: this.store.iso_code
    };

    const postParams = {
        product_id: this.item.id,
        user_id: this.userId
    };

    this.wishlistService.removeFromWishlist(getParams, postParams)
            .pipe(first())
            .subscribe(
                response => {
                    if (response.status === 200) {
                        let items = response.data;
                        this.userService.setWishlist(items);
                        this.in_wishlist = !this.in_wishlist;
                    } 

                    //this.configSettings.toggleLoading(false);
                }
            );

    return false;
  }

  addToWishlist() {
    //this.configSettings.toggleLoading(true);
    const getParams = {
        lang: this.lang,
        store: this.store.iso_code
    };

    const postParams = {
        product_id: this.item.id,
        user_id: this.userId
    };

    this.wishlistService.addToWishlist(getParams, postParams)
            .pipe(first())
            .subscribe(
                response => {
                    if (response.status === 200) {
                        let items = response.data;
                        this.userService.setWishlist(items);
                        this.in_wishlist = !this.in_wishlist;
                    } 

                    //this.configSettings.toggleLoading(false);
                }
            );

    return false;
  }
}
