import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './pipes/SafeHtmlPipe';
import { GtagModule } from 'angular-gtag';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './/app-routing.module';
import { HeaderUserActionsComponent } from './header-user-actions/header-user-actions.component';
import { CRYPT_CONFIG_PROVIDER, CryptConfigProvider, EncryptionServiceModule } from 'angular-encryption-service';
import { ConfigSettings } from './config/config.settings';
import { HeaderComponent } from './header/header.component';
import { SideUserActionsComponent } from './side-user-actions/side-user-actions.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { CartComponent } from './cart/cart.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { BrandListComponent } from './brand-list/brand-list.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { OrderListComponent } from './order-list/order-list.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { MyAddressComponent } from './my-address/my-address.component';
import { RegisterComponent } from './register/register.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ShippingAddressComponent } from './shipping-address/shipping-address.component';
import { FooterComponent } from './footer/footer.component';
import { ShippingAddressFormComponent } from './shipping-address-form/shipping-address-form.component';
import { CheckoutProgressComponent } from './checkout-progress/checkout-progress.component';
import { PaymentComponent } from './payment/payment.component';
import { CmsComponent } from './cms/cms.component';
import { FaqComponent } from './faq/faq.component';
import { ProductItemComponent } from './product-item/product-item.component';
import {
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
    MAT_DIALOG_DEFAULT_OPTIONS,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule
} from "@angular/material";
import { AddedToCartDialogComponent } from './added-to-cart-dialog/added-to-cart-dialog.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxPopper } from 'angular-popper';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { UserAccountMenuComponent } from './user-account-menu/user-account-menu.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LoadingComponent } from './loading/loading.component';
import { DialogComponent } from './dialog/dialog.component';
import { CartSummaryComponent } from './cart-summary/cart-summary.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ConfirmPasswordValidatorDirective } from './shared/confirm-password-validator.directive';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";
import { AddressComponent } from "./address/address.component";
import { SlickModule } from "ngx-slick";
import { JsonpModule } from "@angular/http";
import { DeepLinkComponent } from './deep-link/deep-link.component';
import { ReturnRequestListComponent } from './return-request-list/return-request-list.component';
import { RequestReturnComponent } from './request-return/request-return.component';
import { ReturnRequestDetailComponent } from './return-request-detail/return-request-detail.component';
import { ShareButtonsModule } from '@ngx-share/buttons';
import { MyHttpInterceptor } from './interceptors/MyHttpInterceptor';
import { NgxZendeskWebwidgetModule, NgxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
    lazyLoad = true;
    accountUrl = 'bazmashop.zendesk.com';
    callback(zE) {
        // You can call every command you want in here
        zE('webWidget', 'hide');
    }
}

const AppCryptConfigProvider: CryptConfigProvider = {
    getSalt(): Promise<string> {
        return Promise.resolve(ConfigSettings.ENCRYPT_KEY);
    }
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

let config = new AuthServiceConfig([
    {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider("630977078038940") //202012753892439
    },
]);

export function provideConfig() {
    return config;
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        HeaderUserActionsComponent,
        HeaderComponent,
        SideUserActionsComponent,
        ProductListComponent,
        ProductDetailComponent,
        CartComponent,
        CategoryListComponent,
        BrandListComponent,
        WishlistComponent,
        MyOrdersComponent,
        OrderListComponent,
        MyAccountComponent,
        MyAddressComponent,
        RegisterComponent,
        ShippingAddressComponent,
        FooterComponent,
        CmsComponent,
        SafeHtmlPipe,
        FaqComponent,
        FooterComponent,
        ShippingAddressFormComponent,
        CheckoutProgressComponent,
        PaymentComponent,
        ProductItemComponent,
        PaymentComponent,
        AddedToCartDialogComponent,
        OrderConfirmationComponent,
        UserAccountMenuComponent,
        ChangePasswordComponent,
        LoadingComponent,
        DialogComponent,
        CartSummaryComponent,
        ForgotPasswordComponent,
        ConfirmPasswordValidatorDirective,
        CartSummaryComponent,
        OrderDetailComponent,
        AddressComponent,
        DeepLinkComponent,
        ReturnRequestListComponent,
        RequestReturnComponent,
        ReturnRequestDetailComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        AppRoutingModule,
        EncryptionServiceModule.forRoot(),
        DragScrollModule,
        NgSelectModule,
        MatDialogModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatTabsModule,
        MatSnackBarModule,
        BrowserAnimationsModule,
        MatInputModule,
        //BrowserAnimationsModule,
        //NoopAnimationsModule,
        NgxPopper,
        ClickOutsideModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SocialLoginModule,
        SlickModule.forRoot(),
        ShareButtonsModule.forRoot(),
        GtagModule.forRoot({ trackingId: 'UA-128368520-1', trackPageviews: true }),
        NgxZendeskWebwidgetModule.forRoot(ZendeskConfig)
    ],
    entryComponents: [
        AddedToCartDialogComponent,
        DialogComponent
    ],
    providers: [
        { provide: CRYPT_CONFIG_PROVIDER, useValue: AppCryptConfigProvider },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
        {
            provide: AuthServiceConfig,
            useFactory: provideConfig
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MyHttpInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

