import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";
import {ConfigService} from "../config/config.service";
import {Router} from "@angular/router";
import {ConfigSettings} from "../config/config.settings";
import { CartService } from '../config/cart.service';
import { WishlistService } from '../config/wishlist.service';
import { AuthService } from 'angularx-social-login';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { User } from '../models/user';

declare var $: any;

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  reaon: string;
  requestReason: string;
  requestReasonText: string;
  lang: string;
  user: User;
  confirmMessage:string;
  maxReturnQuantity: number;
  returnQuantity: number;
  quantityOptions: any[] = [];
  
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dialogRef: MatDialogRef<DialogComponent>,
      private configService: ConfigService,
      private router: Router,
      private snackBarRef: MatSnackBar,
      private configSettings: ConfigSettings,
      private authService: AuthService,
      private wishlistService: WishlistService,
      private cartService: CartService,
      protected localStorage: LocalStorage
  ) { }

  ngOnInit() {
    this.lang = this.configSettings.getLang();
    if(this.data.images) {
        let imageCount = this.data.images.length;
        $(document).ready(function() {
            $('.slider-gallery').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                fade: true,
                responsive: true,
                asNavFor: '.thumb',
                prevArrow: '<span class="slick-prev" style=""><span class="slick-prev-icon"></span></span>',
                nextArrow: '<span class="slick-next" style=""><span class="slick-next-icon"></span></span>'
            });
            $('.thumb').slick({
                infinite: false,
                slidesToShow: imageCount,
                slidesToScroll: 1,
                asNavFor: '.slider-gallery',
                arrows: true,
                dots: false,
                vertical: true,
                focusOnSelect: true
            });
        });
    }

    if(this.data.user){
        this.user = this.data.user;
    }

    /*
    * Order item return quantity
    * */
    if(this.maxReturnQuantity) {
        this.returnQuantity = this.maxReturnQuantity;
        for (let i = 1; i <= this.returnQuantity; i++) {
            this.quantityOptions.push({value: i, label: i});
        }
    }
  }

  closeDialog() {
      this.dialogRef.close();
  }

    cancelOrder() {
        this.configSettings.toggleLoading(true);
        const getParams = {
            id: this.data.id,
            user: this.data.user
        };

        const postParams = {
            reason: this.reaon
        };

        this.configService.postRequest('cancel-order', getParams, postParams)
            .subscribe(response => {console.log(response);
                this.configSettings.toggleLoading(false);
                this.dialogRef.close();
                if (response.status !== 200) {
                    let snackBarRef = this.snackBarRef.open(response.message, null, {
                        duration: 5000
                    });
                } else {
                    this.router.navigate(['my-orders']);
                }

            }, error => { this.configSettings.toggleLoading(false); });
    }

    closeModal() {
      this.dialogRef.close();
    }

    logout() {
        if(this.user.is_social_register){
            this.authService.signOut();
        }
        this.wishlistService.changWishlistCount(0);
        this.cartService.changCartCount(0);
        this.localStorage.removeItem('userInfo').subscribe(() => {
            window.location.href = '/';
        });
    }

    submitReturnReason() {
      const reason = (this.requestReasonText) ? this.requestReasonText : this.requestReason;
        this.dialogRef.close({ data: {reason: reason, quantity: this.returnQuantity} });
    }

}
