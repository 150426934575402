import { Component, OnInit } from '@angular/core';
import {CheckoutService} from "../config/checkout.service";

@Component({
  selector: 'app-checkout-progress',
  templateUrl: './checkout-progress.component.html',
  styleUrls: ['./checkout-progress.component.css']
})
export class CheckoutProgressComponent implements OnInit {
  steps: Number = 1;
  constructor(
      private checkoutService: CheckoutService
  ) { }

  ngOnInit() {
      //this.checkoutService.change.subscribe(steps => {this.steps = steps;});
      this.steps = this.checkoutService.step;
  }

}
