import { Component, OnInit } from '@angular/core';
import {UserService} from "../config/user.service";
import {CartService} from "../config/cart.service";
import {ConfigSettings} from "../config/config.settings";
import {Address} from "../models/address";
import {Store} from "../models/store";
import {ConfigService} from "../config/config.service";
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';

@Component({
  selector: 'app-my-address',
  templateUrl: './my-address.component.html',
  styleUrls: ['./my-address.component.css'],
  animations: [ listStagger ]
})
export class MyAddressComponent implements OnInit {
  addresses: Address[] = [];
  userId: string;
  lang: string;
  didLoad: boolean = false;
  store: Store;
  currency: string = '';
  dataAvailable = false;

  constructor(
      private userService: UserService,
      private configSettings: ConfigSettings,
      private configService: ConfigService,
      protected footerService: FooterService
  ) { }

  ngOnInit() {
      this.lang = this.configSettings.getLang();
      this.configSettings.getStoreObject().then((result) => {
        this.store = <Store> result;
        this.currency = result['currency_' + this.lang];
        this.userService.getUserSession().then(user => {
            if(user !== false) {
                this.userId = user.id;
            }

            this.getUserAddress();
        });
      });
  }

  getUserAddress() {
      this.configSettings.toggleLoading(true);
      this.userService.getUserAddress(this.userId, this.lang, this.store.iso_code).then( data => {
          this.addresses = data;
          this.configSettings.toggleLoading(false);
          this.didLoad = true;
          this.dataAvailable = true;
          setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
      }, error => { this.configSettings.toggleLoading(false); });
  }

  deleteAddress(id) {
      this.configSettings.toggleLoading(true);
      const getParams = {
          lang: this.lang
      };

      const postParams = {
          id: id,
          user_id: this.userId
      };

      this.configService.postRequest('delete-address', getParams, postParams)
          .subscribe(response => {
              if (response.status === 200) {
                  this.addresses = response.data;
              }
              this.configSettings.toggleLoading(false);
          }, error => { this.configSettings.toggleLoading(false); });

      return false;
  }

  makeDefault(id) {
      this.configSettings.toggleLoading(true);
      const getParams = {
          lang: this.lang
      };

      const postParams = {
          id: id,
          user_id: this.userId
      };

      this.configService.postRequest('make-default-address', getParams, postParams)
          .subscribe(response => {
              if (response.status === 200) {
                  this.addresses = response.data;
              }
              this.configSettings.toggleLoading(false);
          }, error => { this.configSettings.toggleLoading(false); });

      return false;
  }

}
