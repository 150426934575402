import {Component, OnDestroy, OnInit} from '@angular/core';
import {Brands} from "../models/brands";
import {Category} from "../models/category";
import {ConfigService} from "../config/config.service";
import {first} from "rxjs/operators";
import {ConfigSettings} from "../config/config.settings";
import { FooterService } from '../config/footer.service';
import { listStagger } from '../animations';

@Component({
  selector: 'app-brand-list',
  templateUrl: './brand-list.component.html',
  styleUrls: ['./brand-list.component.css'],
  animations: [ listStagger ]
})
export class BrandListComponent implements OnInit, OnDestroy {
  brands: Brands[] = [];
  lang: string;
  groups: string[] = [];
  divider: number;
  dataAvailable = false;

  constructor(
      private configService: ConfigService,
      public configSettings: ConfigSettings,
      protected footerService: FooterService

  ) { }

  ngOnInit() {
    this.getBrands();
    window.addEventListener('scroll', this.scroll, true);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  getBrands() {
      const params = {
          lang: this.lang
      };
      this.configService.readRequest('all-brands', params)
          .subscribe(response => {
              if (response.body.data) {
                  this.brands =  <Brands[]> response.body.data;
                  this.brands.sort((b1, b2): number => {
                    if(b1.name.toLowerCase() < b2.name.toLowerCase()) return -1;
                      if(b1.name > b2.name) return 1;
                  });
                  this.createGroups();
              }

              this.dataAvailable = true;
              setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
          },
      error => {
              console.log(error);
          },
      );
  }

  createGroups() {
      this.brands.map(brand => {
        let firstCharacter = brand.name.charAt(0);

        if(typeof firstCharacter === "string" && this.groups.findIndex(char => firstCharacter === char) === -1) {
          this.groups.push(firstCharacter.toUpperCase());
        } else if(typeof firstCharacter === "number" && this.groups.findIndex(char => "123" === char) === -1) {
            this.groups.push("123");
        }
      });

      this.groups.sort();
      this.divider = Math.ceil(this.groups.length / 4);
  }

  scroll = (): void => {
    let headerheight = document.getElementById('page-header').offsetHeight;
    let notificationHeight = document.getElementById('notifications').offsetHeight;
    let titleHeight = document.querySelector('.maintitle-Container').clientHeight + headerheight;

    if(window.scrollY >= notificationHeight) {
      document.querySelector('.body-wrap').classList.add('sticky-wrap');
      document.querySelector('.maintitle-Container').setAttribute("style", "top: "+headerheight+"px");
      document.querySelector('.letters').setAttribute("style", "top: "+titleHeight+"px");
    } else {
      document.querySelector('.body-wrap').classList.remove('sticky-wrap');
      document.querySelector('.maintitle-Container').setAttribute("style", "top: 0px");
      document.querySelector('.letters').setAttribute("style", "top: 0px");
    }
  }

  scrollTo($event, target) {
      // document.getElementById(target).scrollIntoView({behavior:"smooth", block: "start", inline: "start"});
      let notificationHeight = document.getElementById('notifications').offsetHeight;
      let t = document.getElementById(target).offsetTop;

      if(t >= notificationHeight) {
        t += notificationHeight;
      }
      window.scrollTo({
          top: t,
          behavior: "smooth"
      });

      let letters = document.querySelectorAll('.letter');
      for(let i = 0; i < letters.length; i++) {
        letters[i].classList.remove('filtered');
      }
      $event.target.classList.add('filtered');

      let groups = document.querySelectorAll('.brand-group');
      for(let i = 0; i < groups.length; i++) {
          groups[i].classList.add('brand-group-dim');
      }
      document.getElementById(target).classList.remove('brand-group-dim');
  }

  brightAll($event) {
      let groups = document.querySelectorAll('.brand-group');
      for(let i = 0; i < groups.length; i++) {
          groups[i].classList.remove('brand-group-dim');
      }
  }
}
