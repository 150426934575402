import {Component, DoCheck, HostListener, OnInit} from '@angular/core';
import {ConfigService} from '../config/config.service';
import {ActivatedRoute} from '@angular/router';
import {Product} from '../models/product';
import {Attributes} from "../models/attributes";
import {CryptService} from "../config/crypt.service";
import {CartService} from "../config/cart.service";
import {LocalStorage} from "@ngx-pwa/local-storage";
import {ConfigSettings} from "../config/config.settings";
import {MatDialog} from "@angular/material";
import {AddedToCartDialogComponent} from "../added-to-cart-dialog/added-to-cart-dialog.component";
import {Store} from "../models/store";
import {ConfigurableOptions} from "../models/configurable-options";
import {DialogComponent} from "../dialog/dialog.component";
import {UserService} from "../config/user.service";
import { WishlistService } from '../config/wishlist.service';
import { listStagger, fade } from '../animations';
import { FooterService } from '../config/footer.service';
import {reject} from 'q';
import { Title, Meta } from '@angular/platform-browser';


declare var branch: any;
declare var ackoo: any;

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: [
      './product-detail.component.css'
  ],
  animations: [ listStagger, fade ]
})
export class ProductDetailComponent implements OnInit {
  product: Product;
  id: number;
  selectedAttribute: any[] = [];
  selectedOption: Number[] = [];
  selectedFilters: ConfigurableOptions[] = [];
  entityId: string;
  quantity: number;
  quantityArr: any[] = [];
  lang: string;
  store: Store;
  currency: string;
  userId: string;
  dataAvailable = false;
  slideConfig: any = {
      variableWidth: false,
      infinite: true,
      speed: 300,
      slidesToShow: 2,
      slidesToScroll: 1,
      dots: true,
      prevArrow: '<span class="slick-prev" style=""><span class="slick-prev-icon"></span></span>',
      nextArrow: '<span class="slick-next" style=""><span class="slick-next-icon"></span></span>'
  };
  branchIoLink: string;

  constructor(
      private configService: ConfigService,
      private configSettings: ConfigSettings,
      private route: ActivatedRoute,
      private cryptService: CryptService,
      private cartService: CartService,
      protected localStorage: LocalStorage,
      private dialog: MatDialog,
      private userService: UserService,
      private wishlistService: WishlistService,
      protected footerService: FooterService,
      private metaService: Meta
  ) {
      this.quantity = 1;
  }

  ngOnInit() {
    this.configSettings.toggleLoading(true);
    if (window.outerWidth <= 767) {
        this.slideConfig = {
            variableWidth: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            prevArrow: '<span class="slick-prev" style=""><span class="slick-prev-icon"></span></span>',
            nextArrow: '<span class="slick-next" style=""><span class="slick-next-icon"></span></span>'
        };
    }
    this.id = +this.route.snapshot.paramMap.get('id');
    this.lang = this.configSettings.getLang();

    this.userService.getUserSession().then(user => {
        if (user !== false) {
            this.userId = user.id;
        }

        /* getting store from config*/
        this.configSettings.getStoreObject().then((result) => {
            this.store = <Store> result;
            this.currency = result['currency_' + this.lang];
            this.getProductDetails();
        });
    }, error => { this.configSettings.toggleLoading(false); }); 
  }

  getProductDetails() {
      const params = {
          product_id: this.id,
          lang: this.lang,
          user_id: this.userId,
          store: this.store.iso_code
      };
      this.configService.readRequest('product-details', params)
          .subscribe(response => {
              if (response.status === 200) {
                  this.product = response.body.data;

                  //   ackoo integration
                  ackoo.trackViewItem({ 
                    itemId: response.body.data.id, 
                    name: response.body.data.name
                  });

                  this.product.parent_id = this.product.id;
                  this.entityId = (this.product.configurable_option.length === 0) ? this.product.id : null;
                  /*
                  * Auto selecting dropdown if there is only one option
                  * */
                  if (this.product.configurable_option.length > 0) {
                      let singleOption = true;
                      for (let i = 0; i < this.product.configurable_option.length; i++) {
                          if (this.product.configurable_option[i].attributes.length === 1) {
                              this.selectedAttribute[i] = this.product.configurable_option[i].attributes[0].option_id;
                          } else {
                              singleOption = false;
                              break;
                          }
                      }
                      if (singleOption) {
                          this.entityId = this.product.id;
                      } else {
                          this.selectedAttribute = [];
                      }
                  }
                  /*
                  * Adding quantity options in dropdown if quantity is available and product is simple
                  * */
                  if (this.product.product_type === 'Simple' && this.product.remaining_quantity > 0) {
                      this.setQuantityDropdown(this.product.remaining_quantity);
                  } else {
                      this.quantityArr = [];
                  }
                  const final_price = this.product.final_price_kwd * 3.30;
                  this.sendGTagEvent('view_item', {
                      //"content_type": "product",
                      event_category: 'ecommerce',
                      items: [{
                          id: this.product.SKU,
                          name: this.product.name,
                          list_name: "Product Details",
                          brand: this.product.brand_name,
                          category: this.product.brand_name,
                          variant: (this.product.configurable_option.length > 0) ? this.product.configurable_option[0].attributes[0].value : '',
                          list_position: 1,
                          quantity: this.product.remaining_quantity,
                          price: final_price.toFixed(2)
                      }]
                  });

                  this.configSettings.toggleLoading(false);
                  this.dataAvailable = true;
                  setTimeout(() => {
                      this.footerService.toggleFooter(true)
                  }, 300);

                  this.registerBranch();
                  var options = { no_journeys: true };
                //   branch.init('key_live_cczY5787HY1iXKOjYwyoZnipAqaSKBze', options, function(err, data) { // key_test_ndzZ2665S95j2JJpWDtCAhikurjUHvrz
                  branch.init('key_live_nm268a5wUv1i0q3nvBCogecgzAg6uNec', options, function(err, data) { // key_test_ndzZ2665S95j2JJpWDtCAhikurjUHvrz
                      //console.log(err, data);
                  });

                  var linkData = {
                      campaign: 'content 123',
                      channel: 'facebook',
                      feature: 'share',
                      stage: 'new user',
                      tags: [ 'tag1', 'tag2', 'tag3' ],
                      alias: '',
                      data: {
                          'custom_bool': true,
                          'custom_int': Date.now(),
                          'custom_string': 'hello',
                          '$og_title': 'Shop ' + this.product.name,
                          '$og_description': this.product.description,
                          '$og_image_url': this.product.image,
                          'target': 'P',
                          'target_id': this.product.id
                      }
                  };

                  branch.link(linkData, (err, link) => {
                      this.branchIoLink = link;
                  });
                  /*
                  * Adding Microdata Metadata
                  * */
                  this.metaService.addTag( { property: 'og:title', content: this.product.name } );
                  this.metaService.addTag( { property: 'og:description', content: this.product.description } );
                  this.metaService.addTag( { property: 'og:url', content: window.location.href } );
                  this.metaService.addTag( { property: 'og:image', content: this.product.image } );
                  this.metaService.addTag( { property: 'product:brand', content: this.product.brand_name } );
                  this.metaService.addTag( { property: 'product:price:amount', content: this.product.final_price } );
                  this.metaService.addTag( { property: 'product:price:currency', content: this.product.currency_code } );
                  this.metaService.addTag( { property: 'product:retailer_item_id', content: this.product.SKU } );
              } else {
                  this.configSettings.toggleLoading(false);
              }
          }, error => { this.configSettings.toggleLoading(false); });
  }

    onOptionSelect(option, $event) {
        /*setTimeout(() => document.getElementById('option-' + $event.option)
            .querySelector<HTMLInputElement>('.ng-select-container > .ng-value-container > .ng-value > .ng-value-label').innerHTML = $event.selectedText,
            5);*/
        document.getElementById('option-' + option.attribute_id).classList.remove('error');
        this.clearFilters(option, $event).then(() => {
            this.filterOptions();
        });
    }

    clearFilters(configurable_option:ConfigurableOptions, $event): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            this.product.configurable_option.map((option, index) => {
                if(option.attribute_id === configurable_option.attribute_id) {
                    if(index == 0) {
                        this.selectedAttribute.splice(1);
                        this.selectedOption.splice(1);
                        this.selectedFilters.splice(1);
                    }

                    this.selectedAttribute[index] = $event.option_id;
                    this.selectedOption[index] = +configurable_option.attribute_id;
                    this.selectedFilters[index] = {
                        type: configurable_option.type,
                        attribute_id: configurable_option.attribute_id,
                        attribute_code: configurable_option.attribute_code,
                        attributes: [$event]
                    };
                }
            });

            resolve(true);
        });

        return promise;

    }

    addToBag() {
        try {
            if(this.product.is_saleable) {
                this.configSettings.toggleLoading(true);
                if(this.entityId) {
                    this.localStorage.getItem<any>('userInfo').subscribe((userInfo) => {
                        if (userInfo) {
                            this.addToOnlineCart(userInfo).then((any) => {
                                const final_price = this.product.final_price_kwd * 3.30;
                                this.sendGTagEvent('add_to_cart', {
                                    items: [{
                                        id: this.product.SKU,
                                        name: this.product.name,
                                        list_name: "Product Details",
                                        brand: this.product.brand_name,
                                        category: this.product.brand_name,
                                        variant: (this.product.configurable_option.length > 0) ? this.product.configurable_option[0].attributes[0].value : "",
                                        list_position: 1,
                                        quantity: this.quantity,
                                        price: final_price.toFixed(2)
                                    }]
                                });

                                this.configSettings.toggleLoading(false);
                                const dialogRef = this.dialog.open(AddedToCartDialogComponent);


                                // ackoo integreation
                                var ackooCurrency = '';

                                switch (this.product.currency_code) {
                                    case 'KD':
                                        ackooCurrency = 'kwd'
                                        break;

                                    case 'SR':
                                        ackooCurrency = 'sar'
                                        break;
                                    
                                    case 'QR':
                                        ackooCurrency = 'qar'
                                        break;
                                    
                                    case 'BD':
                                        ackooCurrency = 'bhd'
                                        break;
                                    
                                    case 'OR':
                                        ackooCurrency = 'omr'
                                        break;
                                    
                                    case 'AED':
                                        ackooCurrency = 'aed'
                                        break;
                                    
                                    default:
                                        ackooCurrency = this.product.currency_code
                                        break;
                                }

                                ackoo.trackAddToCart({
                                    itemId: this.product.final_price,
                                    name: this.product.name,
                                    quantity: this.quantity,
                                    price: parseInt(this.product.final_price),
                                    currency: ackooCurrency
                                });

                            }, error => { console.log('Error occured while adding product to cart!'); console.log(error); });
                        } else {
                            this.cartService.addToOfflineCart(this.entityId, this.product, this.selectedFilters, this.quantity).then(response => {
                                this.configSettings.toggleLoading(false);
                                const dialogRef = this.dialog.open(AddedToCartDialogComponent);
                            });
                        }
                    }, error => { this.configSettings.toggleLoading(false); });
                } else {
                    this.configSettings.toggleLoading(false);
                    let selects = document.querySelectorAll('ng-select');
                    for (let i = 0; i < selects.length; i++) {
                        selects[i].classList.add('error');
                    }
                }
            }
        } catch (e) {
            this.configSettings.toggleLoading(false);
            console.log(e);
        }
    }

    private addToOnlineCart(user: string): Promise<any> {
        return new Promise(resolve => {
            this.cryptService.doDecrypt(user).then(decryptedString => {
                const user = JSON.parse(decryptedString);

                const getParams = {
                    lang: this.lang,
                    store: this.store.iso_code
                };
                const postParams = {
                    user_id: user.id,
                    products: this.entityId,
                    quantity: this.quantity
                };
                this.configService.postRequest('add-to-cart', getParams, postParams)
                    .subscribe(response => {
                        if (response.status === 200) {
                            this.configSettings.setCartId(response.data.id);
                            this.cartService.changCartCount(response.data.items.length);
                            resolve(true);
                        } else {
                            reject(response);
                        }
                    }, error => { reject(error); });
            });
        });
    }

    toggleWishList($event) {
        if(this.userId){
            this.configSettings.toggleLoading(true);
            const getParams = {
                lang: this.lang,
                store: this.store.iso_code
            };

            const postParams = {
                product_id: this.id,
                user_id: this.userId
            };

            if(this.product.item_in_wishlist) {
                this.configService.postRequest('remove-wishlist', getParams, postParams)
                    .subscribe(response => {
                        if (response.status.toString() === "200") {
                            this.product.item_in_wishlist = 0;
                            this.configSettings.toggleLoading(false);
                            this.wishlistService.changWishlistCount(response.data.length);
                        } else {
                             this.configSettings.toggleLoading(false);
                        }
                    }, error => { this.configSettings.toggleLoading(false); });
            } else {
                this.configService.postRequest('add-wishlist', getParams, postParams)
                    .subscribe(response => {
                        if (response.status.toString() === "200") {
                            this.product.item_in_wishlist = 1;
                            this.configSettings.toggleLoading(false);
                            this.wishlistService.changWishlistCount(response.data.length);
                        } else {
                             this.configSettings.toggleLoading(false);
                        }
                    }, error => { this.configSettings.toggleLoading(false); });
            }
        } else {
            window.location.href = '/login?returnUrl=product/detail/'+this.id;
        }
    }

    filterOptions() {
        this.configSettings.toggleLoading(true);

        const getParams = {
            lang: this.lang,
            store: this.store.iso_code
        };

        const postParams = {
            product_id: this.product.id,
            option_id: (this.selectedAttribute.length) ? this.selectedAttribute.join(',') : '',
            attribute_id: (this.selectedOption.length) ? this.selectedOption.join(',') : ''
        };

        this.configService.postRequest('configurable-options', getParams, postParams)
            .subscribe(response => {
                if (response.data[0].attributes.length) {
                    this.product.configurable_option.map( option => {
                        if (option.attribute_id === response.data[0].attribute_id) {
                            option.attributes = [];
                            option.attributes = response.data[0].attributes;
                            option.attributes.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
                        }
                    });

                    this.product.images = response.data[0].images;
                    this.quantityArr = [];
                } else {
                    // this.product.currency_code = response.data[0].currency_code;
                    // this.product.final_price = response.data[0].final_price;
                    this.product.image = response.data[0].image_url;
                    this.product.images = response.data[0].images;
                    // this.product.regular_price = response.data[0].regular_price;
                    this.product.SKU = response.data[0].sku;
                    this.product.remaining_quantity = response.data[0].remaining_quantity;
                    this.entityId = response.data[0].entity_id;

                    if (this.product.remaining_quantity > 0) {
                        this.setQuantityDropdown(this.product.remaining_quantity);
                    } else {
                        this.quantityArr = [];
                    }
                }

                this.configSettings.toggleLoading(false);
            }, error => { this.configSettings.toggleLoading(false); });
    }

    showSizeGuide() {
        let dialogRef = this.dialog.open(DialogComponent, {
            data: { value: this.product.size_guide, image: true },
        });
        return false;
    }

    showGallery(index) {
        let dialogRef = this.dialog.open(DialogComponent, {
            data: { images: this.product.images, gallery: true },
            height: '95%',
            width: '95%',
        });
    }

    setQuantityDropdown(remaining_quantity: number) {
        this.quantityArr = [];
        const maxDropdownQuantity = (this.product.remaining_quantity > 10) ? 10 : this.product.remaining_quantity;
        for (let i = 1; i <= maxDropdownQuantity; i++) {
            this.quantityArr.push({value: i, label: i});
        }
        this.quantity = 1;
    }

    registerBranch() {
        this.registerBranchSource(window, document, "script", "branch", function (b, r) { b[r] = function () { b._q.push([r, arguments]); }; }, { _q: [], _v: 1 }, "addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);
    }

    registerBranchSource(b, r, a, n, c, h, _, s) {
        if (!b[n] || !b[n]._q) {
            for (; s < _.length;)
                c(h, _[s++]);
            let d = r.createElement(a);
            d.async = 1;
            d.src = "https://cdn.branch.io/branch-latest.min.js";
            let k = r.getElementsByTagName(a)[0];
            k.parentNode.insertBefore(d, k);
            b[n] = h;
        }
    }

    private sendGTagEvent(event, params) {
        this.configService.sendGTagEvent(event, params);
    }
}
