import { Component, OnInit } from '@angular/core';
import {Category} from "../models/category";
import {ConfigSettings} from "../config/config.settings";
import {ConfigService} from "../config/config.service";

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {
  categories: Category[] = [];
  constructor(
      private configSettings: ConfigSettings,
      private configService: ConfigService
  ) { }

  ngOnInit() {
      this.getAllCategories();
  }

    getAllCategories() {
        this.configSettings.toggleLoading(true);
        const params = {
            lang: this.configSettings.getLang(),
            isWeb: true
        };
        this.configService.readRequest('all-categories', params)
            .subscribe(response => {
                this.categories = response.body.data;
                this.configSettings.toggleLoading(false);
            });
    }

}
