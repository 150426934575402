import { Component, Input, OnInit } from '@angular/core';
import { Address } from "../models/address";
import { DeliveryOptions } from "../models/delivery-options";
import { CheckoutService } from "../config/checkout.service";
import { NavbarService } from "../config/navbar.service";
import { UserService } from "../config/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Order } from "../models/order";
import { PaymentOptions } from "../models/payment-options";
import { ConfigSettings } from "../config/config.settings";
import { Store } from "../models/store";
import { ConfigService } from "../config/config.service";
import { CartService } from '../config/cart.service';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
declare var ackoo;
@Component({
    selector: 'app-order-confirmation',
    templateUrl: './order-confirmation.component.html',
    styleUrls: ['./order-confirmation.component.css'],
    animations: [listStagger]
})
export class OrderConfirmationComponent implements OnInit {
    @Input() order: Order;
    @Input() payment: PaymentOptions;
    currency: string = '';
    lang: string;
    userId: string;
    store: Store;
    paymentMethod: string;
    paymentID: string;
    refId: string;
    paymentResult: string;
    trackId: string;
    transId: string;
    showPaymentDetails = false;
    dataAvailable = false;

    constructor(
        private checkoutService: CheckoutService,
        private navbarService: NavbarService,
        private userService: UserService,
        private router: Router,
        private configSettings: ConfigSettings,
        private route: ActivatedRoute,
        private configService: ConfigService,
        private cartService: CartService,
        protected footerService: FooterService
    ) { }

    ngOnInit() {
        this.lang = this.configSettings.getLang();
        this.navbarService.toggleMainNavigation(false);
        this.checkoutService.setStep(3);
        this.cartService.changCartCount(0);

        /* getting store from config*/
        this.configSettings.getStoreObject().then((result) => {
            this.store = <Store>result;
            this.currency = result['currency_' + this.lang];
        });

        if (this.checkoutService.order) {
            this.order = this.checkoutService.order;
            this.payment = this.checkoutService.payment;
            this.dataAvailable = true;
            this.sendGTagEvent('purchase', this.order.items);

            setTimeout(() => { this.footerService.toggleFooter(true) }, 300);

            console.log(this.checkoutService.order)

            ackoo.trackCheckout({
                orderId: this.checkoutService.order.order_number.toString(),
                amount: Number(this.checkoutService.order.grand_total_kwd),
                currency: "kwd",
            });

        } else {
            this.route.queryParams.subscribe(params => {
                let id = (params['UDF1']) ? params['UDF1'] : '';
                this.paymentID = (params['PaymentID']) ? params['PaymentID'] : '';
                this.paymentResult = (params['Result']) ? params['Result'] : '';
                this.transId = (params['TranID']) ? params['TranID'] : '';
                this.refId = (params['Ref']) ? params['Ref'] : '';
                this.trackId = (params['TrackID']) ? params['TrackID'] : '';
                this.showPaymentDetails = true;
                if (id) {
                    this.configSettings.toggleLoading(true);
                    this.userService.getUserSession().then(user => {
                        if (user !== false) {
                            this.userId = user.id;
                        }
                        this.getOrderDetails(id);
                    }, error => { this.configSettings.toggleLoading(false); });
                } else {
                    this.router.navigate(['/home']);
                }
            });
        }
    }

    getOrderDetails(id) {
        const params = {
            id: id,
            user_id: this.userId,
            lang: this.lang,
            store: this.store.iso_code
        };
        this.configService.readRequest('order-details', params)
            .subscribe(response => {
                this.order = response.body.data;

                ackoo.trackCheckout({
                    orderId: this.order.order_number.toString(),
                    amount: Number(this.order.grand_total_kwd),
                    currency: "kwd",
                });

                if (this.order.payment_mode === 'C') {
                    this.paymentMethod = 'Cash on delivery';
                } else if (this.order.payment_mode === 'K') {
                    this.paymentMethod = 'Knet';
                } else if (this.order.payment_mode === 'CC') {
                    this.paymentMethod = 'Visa/MasterCard';
                }

                this.configSettings.toggleLoading(false);
                this.dataAvailable = true;
                if (this.paymentResult === 'CAPTURED') {
                    this.sendGTagEvent('purchase', this.order.items);
                }

                setTimeout(() => { this.footerService.toggleFooter(true) }, 300);
            });
    }

    sendGTagEvent(event, items: any) {
        const tmp = [];
        items.forEach(function (item, key) {
            const final_price = item.final_price_kwd * 3.30;
            tmp.push({
                id: item.SKU,
                name: item.name,
                list_name: "Product Details",
                brand: item.brand_name,
                category: item.brand_name,
                variant: "",
                list_position: 1,
                quantity: item.quantity,
                price: final_price.toFixed(2).toString()
            });
        });

        let grand_total_kwd = +this.order.grand_total_kwd;
        grand_total_kwd = grand_total_kwd * 3.30;
        this.configService.sendGTagEvent(event, {
            "transaction_id": this.order.order_number.toString(),
            "affiliation": "Online store",
            "value": parseFloat(grand_total_kwd.toFixed(2)),
            'currency': 'USD',
            "tax": +this.order.vat_charges,
            "shipping": +this.order.delivery_charges,
            items: tmp,
        });
    }

}
