import {Injectable} from "@angular/core";
import {CryptService} from "./crypt.service";
import {Cart} from "../models/cart";
import { LocalStorage } from "@ngx-pwa/local-storage";
import {Attributes} from "../models/attributes";
import {Product} from "../models/product";
import {ConfigSettings} from "./config.settings";
import {ConfigService} from "./config.service";
import {Item} from "../models/item";
import {ConfigurableOptions} from "../models/configurable-options";
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: "root"
})
export class CartService {
    private cartCountSource = new BehaviorSubject(0);
    currentcartCount = this.cartCountSource.asObservable();

    constructor(
        public cryptService: CryptService,
        protected localStorage: LocalStorage,
        private configSettings: ConfigSettings,
        private configService: ConfigService
    ) {
        const cartCount = this.configSettings.getCartCount();
        this.changCartCount(cartCount);
    }

    changCartCount(count: any) {
        this.configSettings.setCartCount(count);
        this.cartCountSource.next(count);
    }

    addToOfflineCart(entityId: string, product: Product, configurableOptions: ConfigurableOptions[], quantity: number): Promise<any> {
        return new Promise(resolve => {
            this.localStorage.getItem<string>('cart').subscribe((cart) => {
                if(cart) {
                    this.cryptService.doDecrypt(cart).then(decryptedString => {
                        let cartDetails: Cart = JSON.parse(decryptedString);
                        if (cartDetails.items.length) {
                            const index = cartDetails.items.findIndex(item => item.id === entityId);
                            if(index === -1) {
                                cartDetails.items.push({
                                    id: entityId,
                                    parent_id: product.parent_id,
                                    name: product.name,
                                    brand_name: product.brand_name,
                                    short_description: product.short_description,
                                    description: product.description,
                                    SKU: product.SKU,
                                    regular_price: product.regular_price,
                                    final_price: product.final_price,
                                    base_currency_id: 0,
                                    currency_code: product.currency_code,
                                    remaining_quantity: product.remaining_quantity,
                                    quantity: quantity,
                                    is_featured: product.is_featured,
                                    image: product.image,
                                    configurable_option: configurableOptions,
                                    is_saleable: product.is_saleable,
                                    product_type: product.product_type
                                });
                                this.changCartCount(cartDetails.items.length);
                                this.writeToStorage(cartDetails).then(response => {
                                    resolve(true);
                                })
                            }
                            else {
                                resolve(true);
                            }
                        }
                    });
                } else {
                    product.parent_id = product.id;
                    product.id = entityId;
                    product.quantity = quantity;
                    let cart: Cart = {
                        id: "",
                        items: [{
                            id: product.id,
                            parent_id: product.parent_id,
                            name: product.name,
                            brand_name: product.brand_name,
                            short_description: product.short_description,
                            description: product.description,
                            SKU: product.SKU,
                            regular_price: product.regular_price,
                            final_price: product.final_price,
                            base_currency_id: 0,
                            currency_code: product.currency_code,
                            remaining_quantity: product.remaining_quantity,
                            quantity: quantity,
                            is_featured: product.is_featured,
                            image: product.image,
                            configurable_option: configurableOptions,
                            is_saleable: product.is_saleable,
                            product_type: product.product_type
                        }],
                        vat_pct: 0,
                        vat_charges: "",
                        shipping_cost: ""
                    };
                    this.changCartCount(1);
                    this.writeToStorage(cart).then(response => {
                       resolve(true);
                    });
                }
            });
        });
    }

    updateOfflineCart(entityId: string, product: Item, configurableOptions: ConfigurableOptions[], quantity: Number): Promise<any> {
        return new Promise(resolve => {
            this.localStorage.getItem<string>('cart').subscribe((cart) => {
                if(cart) {
                    this.cryptService.doDecrypt(cart).then(decryptedString => {
                        let cartDetails: Cart = JSON.parse(decryptedString);
                        if (cartDetails.items.length) {
                            const index = cartDetails.items.findIndex(item => item.id === product.id);
                            if(index !== -1) {
                                cartDetails.items[index] = {
                                    id: entityId,
                                    parent_id: product.parent_id,
                                    name: product.name,
                                    brand_name: product.brand_name,
                                    short_description: product.short_description,
                                    description: product.description,
                                    SKU: product.SKU,
                                    regular_price: product.regular_price,
                                    final_price: product.final_price,
                                    base_currency_id: 0,
                                    currency_code: product.currency_code,
                                    remaining_quantity: product.remaining_quantity,
                                    quantity: 1,
                                    is_featured: product.is_featured,
                                    image: product.image,
                                    configurable_option: configurableOptions,
                                    is_saleable: product.is_saleable,
                                    product_type: product.product_type
                                };
                                this.changCartCount(cartDetails.items.length);
                                this.writeToStorage(cartDetails).then(response => {
                                    resolve(true);
                                })
                            }
                            else {
                                resolve(true);
                            }
                        }
                    });
                }
            });
        });
    }

    writeToStorage(item: any): Promise<any> {
        return new Promise(resolve => {
            this.cryptService.doEncrypt(JSON.stringify(item)).then(encryptedString => {
                this.localStorage.setItem('cart', encryptedString).subscribe(() => {
                    resolve(true);
                });
            });
        });
    }

    clearStorage(key) {
        let promise = new Promise<any>((resolve, reject) => {
            this.localStorage.removeItem(key).subscribe(() => {
                resolve(true);
            });
        });
        return promise;
    }

    getCartCount(): Promise<number> {
        let promise = new Promise<number>((resolve, reject) => {
            this.localStorage.getItem<string>('cart').subscribe((cart) => {
                if(cart) {
                    this.cryptService.doDecrypt(cart).then(decryptedString => {
                        let cartDetails: Cart[] = JSON.parse(decryptedString);
                        resolve(cartDetails.length);
                    });
                }
            });
        });

        return promise;

    }

    getCartValue(): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            this.localStorage.getItem<any>('cart').subscribe((cart) => {
                if(cart) {
                    this.cryptService.doDecrypt(cart).then(decryptedString => {
                        let cartDetails: Cart = JSON.parse(decryptedString);
                        let cartValue: number = 0;

                        cartDetails.items.map(item => {
                            cartValue += (+item.quantity * +item.final_price);
                        });

                        if(cartValue) {
                            resolve(cartValue);
                        }
                        else {
                            resolve(false);
                        }
                    });
                }
            });
        });

        return promise;

    }

    getCartItems(): Promise<Cart> {
        let promise = new Promise<Cart>((resolve, reject) => {
            this.localStorage.getItem<any>('cart').subscribe((cart) => {
                let cartDetails: Cart = null;
                if(cart) {
                    this.cryptService.doDecrypt(cart).then(decryptedString => {
                        cartDetails = JSON.parse(decryptedString);
                        this.changCartCount(cartDetails.items.length);
                        resolve(cartDetails);
                    });
                }
                else {
                    resolve(cartDetails);
                }
            });
        });

        return promise;
    }

    readCartItems(user, lang, store): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            let cartDetails: Cart;
            const params = {
                user_id: user,
                lang: lang,
                store: store
            };
            this.configService.readRequest('cart-items', params)
                .subscribe(response => {
                    this.configSettings.setCartId(response.body.data.id);
                    cartDetails = response.body.data;
                    this.changCartCount(response.body.data.items.length);
                    resolve({cart: cartDetails, shipping: response.body.data.shipping_cost, vatCharges: response.body.data.vat_charges});
                }, error => { reject(error); });
        });

        return promise;
    }

    deleteCartItem(id): Promise<Cart> {
        let promise = new Promise<Cart>((resolve, reject) => {
            this.localStorage.getItem<any>('cart').subscribe((cart) => {
                if (cart) {
                    this.cryptService.doDecrypt(cart).then(decryptedString => {
                        let cartDetails: Cart = JSON.parse(decryptedString);

                        cartDetails.items.map((item, index) => {
                            if (item.id === id) {
                                cartDetails.items.splice(index, 1);
                            }
                        });

                        if (cartDetails.items.length) {
                            this.writeToStorage(cartDetails).then(response => {
                                resolve(cartDetails);
                            });
                        } else {
                            this.clearStorage('cart').then(response => {
                                resolve(cartDetails);
                            });
                        }
                        this.changCartCount(cartDetails.items.length);
                    });
                } else {
                    resolve(null);
                }
            }, error => { reject(error); });
        });

        return promise;

    }

    updateQuantity(index, quantity): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            this.localStorage.getItem<any>('cart').subscribe((cart) => {
                if(cart) {
                    this.cryptService.doDecrypt(cart).then(decryptedString => {
                        let cartDetails: Cart = JSON.parse(decryptedString);
                        let cartValue: number = 0;

                        cartDetails.items[index].quantity = quantity;

                        this.writeToStorage(cartDetails);

                        cartDetails.items.map(item => {
                            cartValue += (+item.quantity * +item.final_price);
                        });

                        if(cartValue) {
                            resolve(cartValue);
                        }
                        else {
                            resolve(false);
                        }
                    });
                }
            });
        });

        return promise;
    }

    uploadOfflineCart(user): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            this.localStorage.getItem<any>('cart').subscribe((cart) => {
                if(cart) {
                    this.cryptService.doDecrypt(cart).then(decryptedString => {
                        let cartDetails: Cart = JSON.parse(decryptedString);

                        if(cartDetails.items.length) {
                            let product: Number[] = [];
                            let quantity: Number[] = [];

                            cartDetails.items.map(item => {
                                product.push(+item.id);
                                quantity.push(item.quantity);
                            });

                            const getParams = {
                                lang: this.configSettings.getLang(),
                                store: this.configSettings.getStore()
                            };
                            const postParams = {
                                user_id: user,
                                products: product.join(','),
                                quantity: quantity.join(',')
                            };
                            this.configService.postRequest('add-to-cart', getParams, postParams)
                                .subscribe(response => {
                                    this.configSettings.setCartId(response.data.id);
                                    this.changCartCount(response.data.items.length);
                                    resolve(true);
                                });
                        } else {
                            resolve(true);
                        }
                    });
                }
                else {
                    resolve(true);
                }
            });
        });

        return promise;
    }

    suggestions(filter: {q: string} = {q: ''}): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            const params = {
                q: filter.q
            };

            this.configService.readRequest('suggestions', params)
                .subscribe(response => {
                    resolve(response);
                });
        });

        return promise;
    }

    search(filter: {q: string} = {q: ''}, page = 1): Observable<any> {
        const params = {
            q: filter.q
        };
        return this.configService.readRequest('suggestions', params)
            .pipe(
                tap((response: any) => {
                    return response.body.data;
                })
            );
    }
}