import { Injectable } from '@angular/core';
import {ConfigService} from "./config.service";
import {CryptService} from "./crypt.service";
import {LocalStorage} from "@ngx-pwa/local-storage";
import { map } from 'rxjs/operators';
import {ConfigSettings} from './config.settings';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
      private configService: ConfigService,
      private configSettings: ConfigSettings
  ) { }

  login(username: string, password: string) {
    const device = this.configSettings.getBrowserVersion();
    const params = {
        email: username,
        password: password,
        device_type: 'W',
        device_model: device,
        app_version: device,
        os_version: window.navigator.platform
    };
    return this.configService.postRequest('login', {}, params)
        .pipe(map(response => {
            return response;
        }));
  }

  socialRegister(params: any) {
    return this.configService.postRequest('social-register', {}, params)
        .pipe(map(response => {
            return response;
        }));
  }

  register(params: any) {
    return this.configService.postRequest('register', {}, params)
        .pipe(map(response => {
            return response;
        }));
  }

  guestRegister(params: any) {
    return this.configService.postRequest('guest-register', {}, params)
        .pipe(map(response => {
            return response;
        }));
  }

  forgotPassword(email: string) {
      const params = {
          email: email,
      };
      return this.configService.postRequest('forgot-password', {}, params)
          .pipe(map(response => {
              return response;
          }));
  }

  editProfile(params: any) {
    return this.configService.postRequest('edit-profile', {}, params)
        .pipe(map(response => {
            return response;
        }));
  }

  changePassword(params: any) {
    return this.configService.postRequest('change-password', {}, params)
        .pipe(map(response => {
            return response;
        }));
  }

}
