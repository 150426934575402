import { Component, OnInit } from '@angular/core';
import {User} from '../models/user';
import {CryptService} from '../config/crypt.service';
import {CartService} from "../config/cart.service";
import {UserService} from "../config/user.service";
import {ConfigSettings} from "../config/config.settings";
import { WishlistService } from '../config/wishlist.service';

@Component({
      selector: 'app-header-user-actions',
      templateUrl: './header-user-actions.component.html',
      styleUrls: ['./header-user-actions.component.css']
})
export class HeaderUserActionsComponent implements OnInit {
    userSession = new User();
    cartCount: number = 0;
    cartValue: string = '';
    wishlistCount: number = 0;

    constructor(
        private cartService: CartService,
        private wishlistService: WishlistService,
        private userService: UserService,
        private configSettings: ConfigSettings
    ) { }

    ngOnInit() {
        this.checkUserSession();
        //this.cartCount = parseInt(this.configSettings.getCartCount());
        this.cartService.currentcartCount.subscribe(data => this.cartCount = data);
        this.wishlistService.currentWishlistCount.subscribe(data => this.wishlistCount = data);
    }

    checkUserSession() {
        this.userService.getUserSession().then(response => {
            if(response !== false) {
                this.userSession.id = response.id;
                this.userSession.first_name = response.first_name;
                this.userSession.last_name = response.last_name;
                this.userSession.email = response.email;
            }
        });
    }


}
