import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {AuthenticationService} from '../config/authentication.service';
import {first} from 'rxjs/operators';
import {ConfigSettings} from '../config/config.settings';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  animations: [ listStagger ]
})
export class ForgotPasswordComponent implements OnInit {

  submitted = false;
  hasError = true;
  message: string;
  email: string;
  lang: string;

  constructor(
      private authenticationService: AuthenticationService,
      private configSettings: ConfigSettings,
      protected footerService: FooterService
  ) { }

  ngOnInit() {
    this.lang = this.configSettings.getLang();
    setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
  }

  onSubmit(forgotPasswordForm: NgForm) {
      this.configSettings.toggleLoading(true);
      this.doForgotPassword();
      forgotPasswordForm.reset();
  }

  doForgotPassword() {
      this.authenticationService.forgotPassword(this.email)
          .pipe(first())
          .subscribe(
              response => {
                  if (response.status === 200) {
                      this.hasError = false;
                      this.message = 'Password successfully reset. Please check your inbox';
                  } else {
                      this.hasError = true;
                      if (response.status === 404) {
                          this.message = 'Email address does not exist';
                      } else {
                          this.message = response.message;
                      }
                  }
                  this.submitted = true;
                  this.configSettings.toggleLoading(false);
              }
          );
  }

}
