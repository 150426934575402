import {HttpHeaders} from '@angular/common/http';
import {EventEmitter, Injectable, Output} from "@angular/core";
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Store} from '../models/store';
import {catchError, map, mergeMap, retry} from "rxjs/operators";
import {of, throwError} from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ConfigSettings {
    // public static API_URL = 'https://admin.bazmashop.com/api/v2/';
    public static API_URL = 'https://admin.bazmashop.com/api/v4apibaz/';
    public static HTTP_OPTIONS = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
    };
    public static ENCRYPT_KEY = 'Leza2018';
    loading = false;
    currency: string;
    store: Store;
    @Output() load: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        protected localStorage: LocalStorage
    ) { }

    cleanUrl(url: string) {
        return url.trim().toLowerCase().replace(/[^a-zA-Z\d\s]/,'').replace(' ','-');
    }

    getLang() {
        return window.localStorage.getItem('lang');
    }

    setLang(lang) {
        window.localStorage.setItem('lang', lang);
    }

    getStore() {
        return window.localStorage.getItem('store');
    }

    setStore(store) {
        window.localStorage.setItem('store', store);
    }

    getStoreObject(): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            const storeObj = this.localStorage.getItem<any>('store_object').pipe(
                mergeMap(result => {
                   if(!result) {
                       return throwError('Error');
                   }

                   return of(result);
                }),
                retry(200)
            );
            storeObj.subscribe({
               next(obj) {
                   this.store = <Store> obj;
                   resolve(this.store);
               },
                error(error) {
                   console.log(error);
                }
            });
            /*this.localStorage.getItem<any>('store_object').subscribe((store_object) => { console.log(store_object);
                if (store_object) {
                    this.store = <Store> store_object;
                    resolve(this.store);
                }
            });*/
        });
        return promise;
    }

    getCurrency() {
        return 'KD';
    }

    setCurrency(currency) {
        window.localStorage.setItem('currency', currency);
    }

    getCartId() {
        return window.localStorage.getItem('cart-id');
    }

    setCartId(cart_id) {
        window.localStorage.setItem('cart-id', cart_id);
    }

    toggleLoading(key: boolean) {
        this.loading = key;
        this.load.emit(this.loading)
    }

    getCartCount() {
        if(window.localStorage.getItem('cart-count') === null){
            this.setCartCount(0);
        }
        return window.localStorage.getItem('cart-count');
    }

    setCartCount(cart_count) {
        window.localStorage.setItem('cart-count', cart_count);
    }

    getWishlistCount() {
        if(window.localStorage.getItem('wishlist-count') === null){
            this.setWishlistCount(0);
        }
        return window.localStorage.getItem('wishlist-count');
    }

    setWishlistCount(cart_count) {
        window.localStorage.setItem('wishlist-count', cart_count);
    }
    getBrowserVersion() {
        let ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem =  /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) { return tem.slice(1).join(' ').replace('OPR', 'Opera'); }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
        return M.join(' ');
    }

    convertCurrency(currency: string) {
        if (currency === 'KD') {
            currency = 'KWD';
        } else if (currency === 'SR') {
            currency = 'SAR';
        } else if (currency === 'QR') {
            currency = 'QAR';
        } else if (currency === 'BD') {
            currency = 'BHD';
        } else if (currency === 'OR') {
            currency = 'OMR';
        }
        return currency;
    }
}
