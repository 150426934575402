import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable({
    providedIn: "root"
})
export class NavbarService {
  navigationStatus = true
  @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  toggleMainNavigation(key: boolean) {
    this.navigationStatus = key;
    this.change.emit(this.navigationStatus)
  }
}
