import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialogRef} from "@angular/material";
import {Location} from '@angular/common';

@Component({
  selector: 'app-added-to-cart-dialog',
  templateUrl: './added-to-cart-dialog.component.html',
  styleUrls: ['./added-to-cart-dialog.component.css']
})
export class AddedToCartDialogComponent implements OnInit {

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private _location: Location,
      private dialogRef: MatDialogRef<AddedToCartDialogComponent>
  ) { }

  ngOnInit() {
  }

    continueShopping() {
      this.dialogRef.close();
      setTimeout(() => { this._location.back(); }, 300);
      //this.router.navigate(['../'], {relativeTo: this.route});
    }

    viewCart() {
        this.dialogRef.close();
        this.router.navigate(['cart']);
    }
}
