import { Component, OnInit } from '@angular/core';
import {User} from "../models/user";
import {UserService} from "../config/user.service";
import { ConfigSettings } from '../config/config.settings';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from '../config/authentication.service';
import { first } from 'rxjs/operators';
import { CartService } from '../config/cart.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { CryptService } from '../config/crypt.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css'],
  animations: [ listStagger ]
})
export class MyAccountComponent implements OnInit {
  user: User;
  lang: string;
  dataAvailable = false;
  submitted = false;
  hasError = false;
  message: string;
  allow_password_change = false;

  constructor(
    private userService: UserService,
    private configSettings: ConfigSettings,
    protected footerService: FooterService,
    protected authenticationService: AuthenticationService,
    private cryptService: CryptService,
    protected localStorage: LocalStorage,
    private cartService: CartService,

  ) { }

  ngOnInit() {
    this.lang = this.configSettings.getLang();
    this.userService.getUserSession().then(response => {
        if (response !== false) {
            this.user = response;
            if (!this.user.is_social_register) {
                this.allow_password_change = true;
            }
            this.dataAvailable = true;
            setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
        }
    });
  }

  /**
   * After user submit form
   * @param updateProfileForm
   */
  onSubmit(updateProfileForm: NgForm) {
      this.configSettings.toggleLoading(true);
      this.doUpdateProfile();
      // updateProfileForm.reset();
  }

  /**
   * Change user password
   */
  doUpdateProfile() {
    // console.log(this.user.first_name);
    try {
      this.configSettings.toggleLoading(true);
      const params = {
        user_id: this.user.id,
        first_name: this.user.first_name,
        dob: this.user.dob
      };
      this.authenticationService.editProfile(params)
            .pipe(first())
            .subscribe(
                response => {
                    this.message = response.message;
                    if (response.status === 200) {
                      this.hasError = false;
                      this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                        this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                            this.cartService.uploadOfflineCart(response.data.id).then(() => {});
                        });
                    });
                    } else {
                      this.hasError = true;
                    }
                    this.submitted = true;
                    this.configSettings.toggleLoading(false);
                }, error => { this.configSettings.toggleLoading(false); }
            );
    } catch (e) {
      this.configSettings.toggleLoading(false);
      console.log(e);
    }
  }
}
