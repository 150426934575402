import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {ConfigSettings} from './config.settings';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs/internal/observable/throwError';
import {Gtag} from "angular-gtag";

@Injectable({
    providedIn: 'root'
})

export class ConfigService {
    constructor(private http: HttpClient, private configSettings: ConfigSettings, private gtag: Gtag) {}

    readRequest(url: string, params: Object): Observable<HttpResponse<any>> {
        params['lang'] = this.configSettings.getLang();
        return this.http.get<any>(ConfigSettings.API_URL + this.serialize(url, params), { observe: 'response'})
            .pipe(catchError(this.handleError));
    }

    readExternalRequest(url: string, params: Object): Observable<HttpResponse<any>> {
        return this.http.get<any>(this.serialize(url, params), { observe: 'response'});
    }

    postRequest(url: string, getParams: Object, postParams: any): Observable<any> {
        return this.http.post<any>(ConfigSettings.API_URL + this.serialize(url, getParams), postParams, ConfigSettings.HTTP_OPTIONS)
            .pipe(catchError(this.handleError));
    }

    serialize(url: string, params: Object) {
        let query = [];
        for (let key in params) {
            if (params.hasOwnProperty(key)) {
                query.push(key + '=' + encodeURIComponent(params[key]));
            }
        }

        return (query.length)? url + '?' + query.join('&') : url;
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        // return throwError('Something bad happened; please try again later.');
        console.log(error);
        return throwError(error);
    }

    sendGTagEvent(event, params) {
        this.gtag.event(event, params);
    }
}
