import { Component, OnInit } from '@angular/core';
import {NavbarService} from "../config/navbar.service";
import {UserService} from "../config/user.service";
import {CartService} from "../config/cart.service";
import {ConfigSettings} from "../config/config.settings";
import {Cart} from "../models/cart";
import {Address} from "../models/address";
import {CheckoutService} from "../config/checkout.service";
import {Router} from "@angular/router";
import { Store } from '../models/store';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';

@Component({
  selector: 'app-shipping-address',
  templateUrl: './shipping-address.component.html',
  styleUrls: ['./shipping-address.component.css'],
  animations: [ listStagger ]
})
export class ShippingAddressComponent implements OnInit {
  cart: Cart = null;
  addresses: Address[] = [];
  address: Address = null;
  currency: string = '';
  cartValue: number = 0;
  shippingCost: number = 0;
  vatCharges: string = "";
  lang: string;
  store: Store;
  dataAvailable = false;

  constructor(
      private navbarService: NavbarService,
      private userService: UserService,
      private cartService: CartService,
      private configSettings: ConfigSettings,
      private checkoutService: CheckoutService,
      private router: Router,
      protected footerService: FooterService
  ) { }

  ngOnInit() {
      this.navbarService.toggleMainNavigation(false);
      this.configSettings.toggleLoading(true);
      this.checkoutService.setStep(1);

      this.lang = this.configSettings.getLang();
      this.configSettings.getStoreObject().then((result) => {
        this.store = <Store> result;
        this.currency = result['currency_' + this.lang];
        
        if (this.checkoutService.cart) {
            this.cart = this.checkoutService.cart;
            this.cart.items.map((item, index) => {
                this.cartValue += (+item.quantity * +item.final_price);
            });
        } else {
            this.getCartItems();
        }
  
        this.getUserAddress();
      }, error => { this.configSettings.toggleLoading(false); });
  }

  getCartItems() {
      this.configSettings.toggleLoading(true);
      this.userService.getUserSession().then(response => {
          if (response !== false) {
              this.cartService.readCartItems(response.id, this.lang, this.store.iso_code).then( data => {
                  if (data.cart.items.length) {
                      this.cart = data.cart;
                      this.cart.items.map((item, index) => {
                          this.cartValue += (+item.quantity * +item.final_price);
                      });
                      // this.shippingCost = data.shipping;
                  } else {
                      this.configSettings.toggleLoading(false);
                      this.navbarService.toggleMainNavigation(true);
                      this.router.navigate(['home']);
                  }
                  this.configSettings.toggleLoading(false);
              }, error => { this.configSettings.toggleLoading(false); });
          } else {
              this.configSettings.toggleLoading(false);
          }
      }, error => { this.configSettings.toggleLoading(false); });
  }

    getUserAddress() {
        this.configSettings.toggleLoading(true);
        this.userService.getUserSession().then(response => {
            if (response !== false) {
                this.userService.getUserAddress(response.id, this.lang, this.store.iso_code).then( data => {
                    if(data.length > 0) {
                        this.addresses = data;
                        this.addresses.map(address => {
                            if(address.is_default === 'Yes') {
                                this.address = address;
                                this.shippingCost = +this.address.delivery_options[0].price;
                            }
                        });
                    } else {
                        this.router.navigate(['shipping-address/add']);
                    }
                    this.configSettings.toggleLoading(false);
                    this.dataAvailable = true;
                    setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
                }, error => { this.configSettings.toggleLoading(false); });
            }
        }, error => { this.configSettings.toggleLoading(false); });
    }

    selectAddress(address, index) {
      document.querySelector('.address-select.active').classList.remove('active');
      document.querySelector('#'+address).classList.add('active');
      this.address = this.addresses[index];
      this.vatCharges = this.address.vat_charges;
      this.shippingCost = +this.address.delivery_options[0].price;
    }

    proceedToPayment() {
      this.checkoutService.setAddress(this.address);
      this.checkoutService.setCart(this.cart);
      this.router.navigate(['payment']);
    }

}
