import { Component, OnInit } from '@angular/core';
import {Store} from "../models/store";
import {UserService} from "../config/user.service";
import {ConfigSettings} from "../config/config.settings";
import {Product} from "../models/product";
import {ConfigService} from "../config/config.service";
import {Router} from "@angular/router";
import { WishlistService } from '../config/wishlist.service';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css'],
  animations: [ listStagger ]
})
export class WishlistComponent implements OnInit {
  lang: string;
  store: Store;
  userId: string;
  didLoad: boolean = false;
  products: Product[] = [];
  currency: string;
  dataAvailable = false;

  constructor(
      private userService: UserService,
      private configSettings: ConfigSettings,
      private configService: ConfigService,
      private router: Router,
      private wishlistService: WishlistService,
      protected footerService: FooterService
  ) {
      this.configSettings.toggleLoading(true);
  }

  ngOnInit() {
      this.lang = this.configSettings.getLang();
      this.currency = this.configSettings.getCurrency();
      this.userService.getUserSession().then(user => {
          if(user !== false) {
              this.userId = user.id;
          }

          /* getting store from config*/
          this.configSettings.getStoreObject().then((result) => {
              this.store = <Store> result;
              this.getWishlist();
          });
      }, error => { this.configSettings.toggleLoading(false); });

      this.userService.wishlistOutput.subscribe(items => {this.products = items;});
  }

  getWishlist() {
      const params = {
          user_id: this.userId,
          lang: this.lang,
          store: this.store.iso_code
      };

      this.configService.readRequest('user-wishlist', params)
          .subscribe(response => {
              this.products = response.body.data;
              this.didLoad = true;
              this.configSettings.toggleLoading(false);
              this.wishlistService.changWishlistCount(response.body.data.length);
              this.dataAvailable = true;
              setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
          }, error => { this.configSettings.toggleLoading(false); });
  }

}
