import { Component, OnInit, Input } from '@angular/core';
import {NavbarService} from "../config/navbar.service";
import {Address} from "../models/address";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../config/user.service";
import {Cart} from "../models/cart";
import {CartService} from "../config/cart.service";
import {ConfigSettings} from "../config/config.settings";
import {Country} from "../models/country";
import {CheckoutService} from "../config/checkout.service";
import {State} from "../models/state";
import {Area} from "../models/area";
import {Sector} from "../models/sector";
import { NgForm } from '@angular/forms';
import { Store } from '../models/store';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';

@Component({
  selector: 'app-shipping-address-form',
  templateUrl: './shipping-address-form.component.html',
  styleUrls: ['./shipping-address-form.component.css'],
  animations: [ listStagger ]

})
export class ShippingAddressFormComponent implements OnInit {
  @Input() address: Address;
    cart: Cart;
    currency: string = '';
    cartValue: number = 0;
    shippingCost: number = 0;
    countries: Country[] = [];
    governorates: State[] = [];
    areas: Area[] = [];
    sectors: Sector[] = [];
    submitted = false;
    hasError = false;
    message: string;
    lang: string;
    store: Store;

  constructor(
      private navbarService: NavbarService,
      private route: ActivatedRoute,
      private userService: UserService,
      private cartService: CartService,
      private configSettings: ConfigSettings,
      private checkoutService: CheckoutService,
      private router: Router,
      protected footerService: FooterService
  ) { }

  ngOnInit() {
      this.lang = this.configSettings.getLang();
      this.navbarService.toggleMainNavigation(false);
      this.address = {
          address_id: null,
          first_name: null,
          last_name: null,
          area_id: null,
          area_name: null,
          governorate_id: null,
          governorate_name: null,
          country_id: 114, // default country Kuwait
          country_name: null,
          vat_pct: null,
          vat_charges: null,
          block_id: null,
          block_name: null,
          shipping_cost: null,
          express_shipping_cost: null,
          cod_cost: null,
          is_cod_enable: null,
          phonecode: null,
          street: null,
          addressline_1: null,
          mobile_number: null,
          alt_phone_number: null,
          location_type: null,
          notes: null,
          is_default: null,
          delivery_options: null
      };

      this.configSettings.getStoreObject().then((result) => {
        this.store = <Store> result;
        this.currency = result['currency_' + this.lang];
        this.getAddress();
        this.getCartItems();
        this.getCountries();
        this.loadStates(114); // default country Kuwait
        setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
      });
  }

    getCartItems() {
        this.configSettings.toggleLoading(true);
        this.userService.getUserSession().then(response => {
            if (response !== false) {
                this.address.first_name = response.first_name +' '+response.last_name;
                this.cartService.readCartItems(response.id, this.lang, this.store.iso_code).then( data => {
                    this.cart = data.cart;
                    this.cart.items.map((item, index) => {
                        this.cartValue += (+item.quantity * +item.final_price);
                    });

                    this.shippingCost = +data.shipping;
                    this.configSettings.toggleLoading(false);
                });
            } else {
                this.configSettings.toggleLoading(false);
            }
        }, error => { this.configSettings.toggleLoading(false); });
    }

  getAddress() {
      this.configSettings.toggleLoading(true);
      const id = this.route.snapshot.paramMap.get('id');
      if (id) {
          this.userService.getUserSession().then(response => {
              if (response !== false) {
                  this.userService.getUserAddressDetails(id, response.id, this.store.iso_code).then(data => {
                      this.address = data.body.data;
                      this.address.area_id = +data.body.data.area_id;
                      this.address.block_id = +data.body.data.block_id;

                      if (this.address.country_id) {
                          this.loadStates(this.address.country_id);
                      }

                      if (this.address.governorate_id) {
                          this.loadAreas(this.address.governorate_id);
                      }

                      if (this.address.area_id) {
                          this.loadBlocks(this.address.area_id);
                      }
                      this.configSettings.toggleLoading(false);
                  });
              } else {
                  this.configSettings.toggleLoading(false);
              }
          }, error => { this.configSettings.toggleLoading(false); });
      } else {
          this.configSettings.toggleLoading(false);
      }
  }

    getCountries() {
      this.configSettings.toggleLoading(true);
      this.checkoutService.getCountries().then(data => {
         this.countries = data.body.data;
          this.configSettings.toggleLoading(false);
      }, error => { this.configSettings.toggleLoading(false); });
    }

    getStates($country) {
        if($country.has_states === '1') {
            this.loadStates($country.id)
        } else {
            this.governorates = [];
        }
        this.address.governorate_id = null;
        this.address.area_id = null;
        this.address.block_id = null;
    }

    loadStates($country) {
        this.configSettings.toggleLoading(true);
        this.checkoutService.getStates($country).then(data => {
            this.governorates = data.body.data;
            this.configSettings.toggleLoading(false);
        }, error => { this.configSettings.toggleLoading(false); });
    }

    getAreas($state) {
        if ($state.has_areas === '1') {
            this.loadAreas($state.id)
        } else {
            this.areas = [];
        }
        this.address.area_id = null;
        this.address.block_id = null;
    }

    loadAreas($state) {
        this.configSettings.toggleLoading(true);
        this.checkoutService.getAreas($state).then(data => {
            this.areas = data.body.data;
            this.configSettings.toggleLoading(false);
        }, error => { this.configSettings.toggleLoading(false); });
    }

    getBlocks($city) {
        if ($city.has_blocks === '1') {
            this.loadBlocks($city.id)
        } else {
            this.sectors = [];
        }
        this.address.block_id = null;
    }

    loadBlocks($city) {
        this.configSettings.toggleLoading(true);
        this.checkoutService.getBlocks($city).then(data => {
            this.sectors = data.body.data;
            this.configSettings.toggleLoading(false);
        }, error => { this.configSettings.toggleLoading(false); });
    }

    saveAddress(shippingAddressForm: NgForm) {
        this.submitted = true;
        this.configSettings.toggleLoading(true);
        this.userService.getUserSession().then(response => {
            if (response !== false) {
                const id = this.route.snapshot.paramMap.get('id');
                if (this.address.block_id === 0) {
                    this.address.block_id = null;
                }
                if (id) {
                    this.checkoutService.updateAddress(response.id, this.address, this.store.iso_code, this.configSettings.getCartId()).then(data => {
                        this.address = data;
                        this.configSettings.toggleLoading(false);
                        // this.router.navigate(['/shipping-address']);
                        this.checkoutService.setAddress(this.address);
                        this.checkoutService.setCart(this.cart);
                        this.router.navigate(['payment']);
                    });
                } else {
                    this.checkoutService.addAddress(response.id, this.address, this.store.iso_code, this.configSettings.getCartId()).then(data => {
                        this.address = data;
                        this.configSettings.toggleLoading(false);
                        // this.router.navigate(['/shipping-address']);
                        this.checkoutService.setAddress(this.address);
                        this.checkoutService.setCart(this.cart);
                        this.router.navigate(['payment']);
                    });
                }
            }
        }, error => { this.configSettings.toggleLoading(false); });
    }

}
